import React, { useEffect } from 'react';
import './Grid.css';
import { Subtitle2 } from '@aurora/widgets-react';
import Pagination from './Pagination';
import { useTable, usePagination, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import { useStores } from '../../hooks/useStores';
import { ENTITY, REPORT_VALID_STATUS } from '../../utils/enum';

const TableMaker = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  controlledPageIndex,
  gridData,
  rowInfo,
  startRow,
  endRow,
  gridEntity,
  noPagination,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: controlledPageIndex,
        pageSize: noPagination ? gridData.length : 100,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      rowInfo,
      startRow,
      endRow,
    },
    useSortBy,
    usePagination
  );
  const { stationStore, measurementStore } = useStores();

  // Listen for changes in pagination and use the state to fetch new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize, pageOptions });
  }, [fetchData, pageIndex, pageSize, pageOptions]);

  const getWithFor = (grids) => {
    if (!grids) return '';
    const selectedGrid = grids.find((grid) => {
      return grid.key === gridEntity;
    });
    if (!selectedGrid) return '';
    return getWidth(selectedGrid);
  };

  const getWidth = (selectedGrid) => {
    if (!selectedGrid.value || gridEntity !== selectedGrid.key) return '';
    const percentIndex =
      selectedGrid.value.length -
      (selectedGrid.value.length - selectedGrid.index);
    if (!selectedGrid.value[percentIndex]) return '';
    const percent = selectedGrid.value[percentIndex];
    return percent;
  };

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  tabIndex="0"
                  key={`make-table ${i}`}
                  style={column.style}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Subtitle2>{column.render('Header')}</Subtitle2>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              // key-prop is returned by row.getRowProps()
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps({
                  onClick: () => {
                    if (
                      gridEntity !== ENTITY.STATIONS_OBSERVATIONS &&
                      gridEntity !== ENTITY.USERS
                    ) {
                      rowInfo(row.original);
                    }
                  },
                  onKeyDown: (e) => {
                    if (e.keyCode === 13) {
                      rowInfo(row.original);
                    }
                  },
                })}
                tabIndex="0"
                className={`${
                  (row &&
                    row.original &&
                    stationStore.selectedStation &&
                    gridEntity === ENTITY.STATIONS &&
                    row.original.natlStationCode ===
                      stationStore.selectedStation.natlStationCode) ||
                  (gridEntity === ENTITY.MEASUREMENTS &&
                    measurementStore.selectedMeasurement &&
                    row.original.observationId ===
                      measurementStore.selectedMeasurement.observationId)
                    ? 'selected'
                    : ''
                } table-row`}
              >
                {row.cells.map((cell, i) => {
                  let color = '#000';
                  let verticalAlign = 'middle';
                  if (gridEntity === ENTITY.AUDIT) {
                    verticalAlign = 'top';
                  }
                  if (i === 3 && gridEntity === ENTITY.REPORT) {
                    Object.keys(REPORT_VALID_STATUS).forEach((status) => {
                      if (status === row.cells[i].value) {
                        color = REPORT_VALID_STATUS[status].color;
                      }
                    });
                  }
                  return (
                    // key-prop is returned by cell.getCellProps()
                    // eslint-disable-next-line react/jsx-key
                    <td
                      style={{
                        verticalAlign: verticalAlign,
                        color: color,
                        width: getWithFor([
                          {
                            key: ENTITY.STATIONS_OBSERVATIONS,
                            value: ['15%', '6%', '69%', '8%', '2%'],
                            index: i,
                          },
                          {
                            key: ENTITY.STATIONS,
                            value: ['19%', '6%', '55%', '10%', '10%'],
                            index: i,
                          },
                          {
                            key: ENTITY.USERS,
                            value: ['10%', '15%', '32%', '41%', '2%'],
                            index: i,
                          },
                        ]),
                      }}
                      {...cell.getCellProps({
                        onClick: () => {
                          if (
                            (gridEntity === ENTITY.STATIONS_OBSERVATIONS ||
                              gridEntity === ENTITY.USERS) &&
                            row.cells.length - 1 !== i
                          ) {
                            rowInfo(row.original);
                          }
                        },
                        onKeyDown: (e) => {
                          if (e.keyCode === 13) {
                            rowInfo(row.original);
                          }
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {loading && (
            <tr>
              <td colSpan="10">
                <Subtitle2>Loading...</Subtitle2>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data.length !== 1 && !noPagination && (
        <Pagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          total={gridData ? gridData.length : 0}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          startRow={startRow}
          endRow={endRow}
        />
      )}
    </>
  );
};

TableMaker.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  controlledPageIndex: PropTypes.number,
  gridData: PropTypes.array,
  rowInfo: PropTypes.func,
  startRow: PropTypes.number,
  endRow: PropTypes.number,
  gridEntity: PropTypes.string,
  noPagination: PropTypes.bool,
};

export default TableMaker;
