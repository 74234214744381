import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Error.css';
import { StatusCard, Button } from '@aurora/widgets-react';
import { CloseOutline } from '@aurora/icons-react';

const Error = ({ error, setError, isStatusPage = false }) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (!error || error === '') {
      setClose(true);
    } else {
      setClose(false);
    }
  }, [error]);

  const closeError = () => {
    if (setError) {
      setError('');
    } else {
      setClose(true);
    }
  };

  return (
    <>
      {!close && (
        <div
          id="error-message-container"
          className={`${isStatusPage ? '' : 'padding-bottom'} `}
        >
          <StatusCard
            style={{ whiteSpace: 'pre-line', maxWidth: '100%' }}
            variant={StatusCard.variants.error}
          >
            <div id="error-label" aria-label={error} tabIndex="0">
              {error}
            </div>
          </StatusCard>
          <Button
            variant={Button.variants.tertiary}
            style={{ padding: isStatusPage ? '0px' : '4px' }}
            label=""
            className="close"
            aria-label="stäng"
            onClick={closeError}
            icon={<CloseOutline className="icon" />}
          >
            {' '}
          </Button>
        </div>
      )}
    </>
  );
};

Error.propTypes = {
  error: PropTypes.string,
  setError: PropTypes.string,
  isStatusPage: PropTypes.bool,
};

export default Error;
