import React from 'react';
import PropTypes from 'prop-types';

const WelcomeText = ({ pageType }) => {
  return (
    <>
      {pageType === 'start' && (
        <span>
          Välkommen till datavärdens rapporteringstjänst för luftkvalitetsdata.
          Här rapporterar du mätdata och metadata från mätningar i tätort
          (gaturum och urban bakgrund) och regional bakgrund till Datavärdskapet
          för luftkvalitet. Detaljerad handledning för rapportering av
          luftkvalitetsdata och metadata via denna rapporteringstjänst finns{' '}
          <a href="https://www.naturvardsverket.se/492a3b/globalassets/vagledning/luft-och-klimat/mkn-utomhusluft/handledning-rapportering-matdata-dv-luftkvalitet.pdf">
            här
          </a>
          .
          <br />
          <br />
          <strong>Mätstationer och mätningar</strong>
          <br />
          Under fliken ”Stationer och mätningar” kan du registrera nya
          mätstationer och mätningar samt redigera uppgifter om befintliga
          mätstationer och mätningar. Du kan även se statusen för olika
          mätstationer och mätningar, dvs. om de är aktiva eller inaktiva samt
          om uppgifterna är publicerade i datavärdens portal eller om de är
          sparade men ännu inte publicerade.
          <br />
          <br /> Observera att du inte ser alla mätstationer och mätningar som
          finns i datavärdens databas, utan endast de som är kopplade till din
          organisation/ditt mätnätverk.
          <br />
          <br />
          <strong>Rapportera mätdata </strong>
          <br />
          Under fliken ”Rapportera mätdata” kan du ladda ner rapporteringsmallar
          och rapportera in (”ladda upp”) mätdata till datavärdens databas. Du
          kan rapportera in årliga metadata, dvs. uppgifter om datakvaliteten
          kopplade till mätdata för ett specifikt år. Du kan även se en översikt
          med statusen för dina olika rapporteringar, t.ex. om data saknas, om
          data har rapporterats in och är under granskning, eller om
          rapporteringen är klar.
          <br />
          <br />
          <strong>Övriga uppgifter</strong>
          <br />
          Under fliken ”Övriga uppgifter” kan du ändra dina kontaktuppgifter och
          uppgifter om organisation och mätnätverk. Under ”Nätverk och dokument”
          kan du rapportera in viktiga dokument som rör kontroll av luftkvalitet
          inom din kommun eller ditt samverkansområde, inkl. kontrollstrategi,
          kvalitetssäkringsprogram och program för samordnad kontroll. <br />
          <br />
          <strong>Realtidsdata</strong>
          <br />
          Under fliken ”Realtidsdata” kan du se statusen för de kontinuerliga
          mätningar som rapporterar (eller som ska rapportera) realtidsdata till
          datavärden. Tabellen visar det senaste mätvärdet som rapporterades för
          alla mätningar som är aktiva (dvs. ej avslutade), klassificerade som
          kontinuerliga mätningar och som mäter med ett automatiskt
          mätinstrument.
          <br />
          <br />
          <strong>Vad händer med data som rapporterats in?</strong>
          <br />
          Data som rapporteras in genomgår först en kvalitetssäkring av
          Referenslaboratoriet för luftkvalitet och Naturvårdsverket, och
          publiceras därefter i datavärdens portal. Vid frågor kontaktar vi dig
          som har lämnat uppgifter. Inrapporterade data rapporteras sedan
          årligen i september till EU av datavärden.
          <br />
          <br /> Observera att registrering av nya mätstationer och mätningar
          samt uppdatering av befintliga stationer och mätningar görs direkt i
          databasen – det är därför viktigt att vara försiktig! Hör av dig till
          datavärden eller Naturvårdsverket om du är osäker eller undrar något.{' '}
          <br />
          <br />
          <strong>Handledning</strong>
          <br />
          Detaljerad handledning för användningen av denna rapporteringstjänst
          finns{' '}
          <a href="https://www.naturvardsverket.se/492a3b/globalassets/vagledning/luft-och-klimat/mkn-utomhusluft/handledning-rapportering-matdata-dv-luftkvalitet.pdf">
            här
          </a>
          .
          <br />
          <br />
          <strong>Frågor</strong>
          <br />
          Här hittar du mer information om rapporteringen av data:{' '}
          <a
            href="https://www.naturvardsverket.se/vagledning-och-stod/luft-och-klimat/miljokvalitetsnormer-for-utomhusluft/rapportera-luftkvalitetsdata/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Rapportera luftkvalitetsdata (naturvardsverket.se)
          </a>
          <br />
          <br />
          Kontakta oss om du har frågor som rör rapporteringen och dessa sidor,
          kvalitetssäkringen eller publiceringen av data.
          <br />
          <br />
          Naturvårdsverket:{' '}
          <a href="mailto:rapporteringluftkvalitet@naturvardsverket.se">
            rapporteringluftkvalitet@naturvardsverket.se
          </a>
          <br />
          Datavärden för luftkvalitet:{' '}
          <a href="mailto:datavardluft@smhi.se">datavardluft@smhi.se</a>
          <br />
          Referenslaboratoriet för luftkvalitet:{' '}
          <a href="mailto:reflabmatningar@aces.su.se">
            reflabmatningar@aces.su.se
          </a>
          <br />
          <br />
        </span>
      )}
      {pageType === 'stationinfo' && (
        <span>
          Här kan du redigera uppgifter om befintliga mätstationer samt
          uppgifter om den omgivande miljön kring en mätstation, t.ex. ÅDT,
          andel tung trafik och skyltad hastighet. Genom knapparna Aktiva och
          Inaktiva går det att filtrera fram pågående respektive avslutade
          mätstationer. Mätstationer avslutas genom att ange ett slutdatum.
          <br />
          <br /> Du kan registrera en ny mätstation genom knappen + Ny
          mätstation. När du väljer en mätstation, följer valet med vid klick på
          flikarna Mätning och Status. Avmarkera valet genom att klicka på
          krysset i Sökrutan. Observera att de ändringar du gör publiceras
          direkt i databasen.
          <br />
          <br />
        </span>
      )}
      {pageType === 'measurement' && (
        <span>
          Här kan du redigera uppgifter som rör mätningar av olika
          luftföroreningar vid en mätstation, t.ex. typ av mätning,
          mätutrustning och uppgifter om luftintaget. Genom knapparna Aktiva och
          Inaktiva går det att filtrera fram pågående respektive avslutade
          mätningar. Mätningar avslutas genom att ange ett slutdatum.
          <br />
          <br />
          När du väljer en mätstation, följer valet med vid klick på flikarna
          Mätstation och Status.
          <br /> Avmarkera valet genom att klicka på krysset i Sökrutan. <br />
          Du kan registrera en ny mätning genom knappen + Ny mätning, t.ex. om
          mätningarna vid stationen har utökats med ett ämne eller om
          mätutrustningen har bytts ut. Observera att de ändringar du gör
          publiceras direkt i databasen.
          <br />
          <br />
        </span>
      )}
      {pageType === 'status' && (
        <span>
          Här får du en snabb överblick över alla mätstationer i databasen som
          är kopplade till din organisation/ditt mätnätverk.
          <br />
          <br /> Om du klickar på pilen Mätningar längst ner i respektive ruta
          kan du se vilka mätningar som finns vid mätstationen. Du får även
          information om mätstationen är aktiv och publicerad i databasen
          (”Klar”), om mätstationen ännu inte har publicerats (”Publicera”)
          eller om mätstationen är avslutad (”Ej aktiv”).
          <br />
          <br />
        </span>
      )}
    </>
  );
};

WelcomeText.propTypes = {
  pageType: PropTypes.string,
};

export default WelcomeText;
