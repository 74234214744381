import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  getOrganisations,
  saveOrganisation,
  deleteOrganisation,
} from '../api/organisation';

export class OrganisationStore {
  selectedOrganisation = null;
  organisations = [];
  organisationTypes = [];
  initialErrorMessage = null;
  organisationFormErrorMessage = null;
  organisationFormSuccessMessage = null;

  constructor(rootStore) {
    makeObservable(this, {
      initialErrorMessage: observable,
      organisationFormErrorMessage: observable,
      selectedOrganisation: observable,
      setSelectedOrganisation: action,
      getOrganisations: action,
      organisations: observable,
      organisationFormSuccessMessage: observable,
      setErrorMessage: action,
      setSuccessMessage: action,
      clearMessages: action,
      setOrganisations: action,
    });

    this.rootStore = rootStore;
  }
  setErrorMessage = (error) => {
    this.organisationFormErrorMessage = error;
  };

  setSuccessMessage = async (success) => {
    this.organisationFormSuccessMessage = success;
  };

  clearMessages = () => {
    this.organisationFormErrorMessage = null;
    this.organisationFormSuccessMessage = null;
  };

  setSelectedOrganisation = (selectedOrganisation) => {
    if (!selectedOrganisation) return;
    this.selectedOrganisation = selectedOrganisation;
  };

  setOrganisations = async (organisations) => {
    this.organisations = organisations;
  };

  async saveOrganisation(data, token) {
    this.clearMessages();
    const response = await saveOrganisation(data, token);
    if (response && response.error) {
      this.setErrorMessage(
        response.error ||
          `Något gick fel när organsiationen skulle ${
            data.id ? 'uppdateras' : 'skapas'
          }`
      );
    } else {
      runInAction(async () => {
        this.setOrganisations([]);
        await this.getOrganisations(token);
        const selectedOrganisation = this.organisations.find((o) => {
          return o.organisationName === data.organisationName;
        });
        this.setSelectedOrganisation(selectedOrganisation);

        data.id
          ? this.setSuccessMessage('Organsiationen uppdaterad')
          : this.setSuccessMessage('Organsiationen skapad');
      });
    }
  }

  async deleteOrganisation(setError, token) {
    const organisation = await deleteOrganisation(
      this.selectedOrganisation.id,
      token
    );
    if (!organisation || organisation.error) {
      setError('Något gick fel när organisationen skulle tas bort');
    } else {
      runInAction(async () => {
        this.setOrganisations([]);
        await this.getOrganisations(token);
        this.selectedOrganisation = null;
      });
    }
  }

  getOrganisations = async (token) => {
    const response = await getOrganisations(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av organisationer';
      this.initialErrorMessage = errorMessage;
    } else {
      this.setOrganisations(response.organisations);
      this.organisationTypes = response.organisationTypes;
    }
  };
}
