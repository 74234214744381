import { makeObservable, observable, action } from 'mobx';
import page from 'page';
import { SELECTED_CONTENT } from '../utils/enum';

export class UiStore {
  measurementSearchResults = [];
  triggerModal = false;
  formDirty = false;
  isLoading = false;
  navigationUrl = '';
  navigateFromValidate = false;
  disableGui = false;
  triggeredFromMultiselect = false;
  triggeredFromDownload = false;
  observationsStatusSearchResults = [];
  createdStationSearchResult = [];
  selectedContent = SELECTED_CONTENT.ALL;

  constructor(rootStore) {
    makeObservable(this, {
      measurementSearchResults: observable,
      setMeasurementSearchResults: action,
      setLoading: action,
      isLoading: observable,
      navigate: action,
      triggerModal: observable,
      observationsStatusSearchResults: observable,
      setObservationsStatusSearchResults: action,
      createdStationSearchResult: observable,
      setCreatedStationSearchResult: action,
      setSelectedContent: action,
      selectedContent: observable,
      navigateFromValidate: observable,
      disableGui: observable,
      triggeredFromMultiselect: observable,
      setNavigateFromValidate: action,
      triggeredFromDownload: observable,
      setTriggeredFromDownload: action,
    });

    this.rootStore = rootStore;
  }

  navigate = (route, useNavigation = true) => {
    if (this.disableGui) return;
    if (this.formDirty) {
      this.triggerModal = true;
      this.navigationUrl = route;
    } else {
      this.triggerModal = false;
      if (useNavigation) {
        page(route, {
          fitBounds: false,
        });
      }
    }
  };

  setMeasurementSearchResults = (list) => {
    this.measurementSearchResults = list;
  };

  setSelectedContent = (selectedContent) => {
    this.selectedContent = selectedContent;
  };

  setCreatedStationSearchResult = (list) => {
    this.createdStationSearchResult = list;
  };

  setSelectedContent = (selectedContent) => {
    this.selectedContent = selectedContent;
  };

  setObservationsStatusSearchResults = (list) => {
    this.observationsStatusSearchResults = list;
  };

  setLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  setNavigateFromValidate = (navigateFromValidate) => {
    this.navigateFromValidate = navigateFromValidate;
  };

  setTriggeredFromDownload = (triggeredFromDownload) => {
    this.triggeredFromDownload = triggeredFromDownload;
  };
}
