import { bearerAuth } from '../utils/funcs';
import { convertToQuery } from '../utils/funcs';

export async function getStationsObservationsStatus(queryObject, token) {
  try {
    const query = convertToQuery(queryObject);
    const END_POINT = '/api/stationsobservationsstatus';
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av stationers observationer status  misslyckades',
      };
    }
    return json;
  } catch (e) {
    return {
      error: 'Hämtningen av stationers observationer status  misslyckades',
    };
  }
}

export async function getObservationsStatus(queryObject, token) {
  try {
    const query = convertToQuery(queryObject);
    const END_POINT = '/api/observationsstatus';
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av stationens observationers status  misslyckades',
      };
    }
    return json;
  } catch (e) {
    return {
      error: 'Hämtningen av stationens observationers status  misslyckades',
    };
  }
}

export async function getAssessmentType(queryObject, token) {
  try {
    const query = convertToQuery(queryObject);
    const END_POINT = '/api/assessmenttype';
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const text = await response.text();
    if (!response.ok) {
      return {
        error: 'Hämtningen av mättyp misslyckades',
      };
    }
    return text;
  } catch (e) {
    return {
      error: 'Hämtningen av mättyp misslyckades',
    };
  }
}

export async function getMetaData(queryObject, token) {
  try {
    const query = convertToQuery(queryObject);
    const END_POINT = '/api/observationmetadata';
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av observationens metadata misslyckades',
      };
    }
    return json;
  } catch (e) {
    return {
      error: 'Hämtningen av observationens metadata misslyckades',
    };
  }
}

export async function updateMetadata(queryObject, token, data) {
  const END_POINT = '/api/observationmetadata';
  const query = convertToQuery(queryObject);
  try {
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att uppdatera aktuell observations metadata',
      };
    }
  } catch (e) {
    return {
      error: 'Det gick inte att uppdatera aktuell observations metadata',
    };
  }
}

export async function insertMetadata(
  queryObject,
  token,
  data,
  setIsSaveingInsert
) {
  const END_POINT = '/api/observationmetadata';
  const query = convertToQuery(queryObject);
  try {
    setIsSaveingInsert(true);
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Det gick inte att skapa aktuell observations metadata',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Det gick inte att skapa aktuell observations metadata' };
  }
}

export async function validateReport(report, token) {
  if (!report || report.files.length === 0) {
    return {
      error: 'Det finns inga filer att validera',
    };
  }
  try {
    let formData = new FormData();
    report.files.forEach((file) => {
      formData.append('files', file);
    });
    const response = await fetch('/api/validate', {
      method: 'POST',
      headers: {
        Authorization: bearerAuth(token),
      },
      body: formData,
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att validera aktuell rapport',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att validera aktuell rapport' };
  }
}

export async function submitReport(formData, token) {
  try {
    const response = await fetch('/api/report', {
      method: 'POST',
      headers: {
        Authorization: bearerAuth(token),
      },
      body: formData,
    });
    if (!response.ok) {
      return {
        error: await response.text().then((text) => {
          return text;
        }),
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att rapportera' };
  }
}

export async function publishMetadata(queryObject, token) {
  const END_POINT = '/api/publishmetdata';
  const query = convertToQuery(queryObject);
  try {
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
    });
    if (!response.ok) {
      return {
        error:
          'Det gick inte att publicera/avpublicera aktuell observations metadata',
      };
    }
  } catch (e) {
    return {
      error:
        'Det gick inte att publicera/avpublicera aktuell observations metadata',
    };
  }
}

export async function downloadTemplate(
  year,
  natlStationCode,
  stationName,
  template,
  token,
  isExtended
) {
  try {
    const response = await fetch(
      `/api/downloadtemplate?year=${year}&natlstationcode=${natlStationCode}&stationname=${stationName}&template=${template}&isextended=${isExtended}`,
      {
        headers: { Authorization: bearerAuth(token) },
      }
    );
    if (!response.ok) {
      return {
        error: `Misslyckades att ladda ner mall för station med Stat.kod ${natlStationCode} och år ${year}`,
      };
    }
    return response;
  } catch (e) {
    return {
      error: `Misslyckades att ladda ner mall för station med Stat.kod ${natlStationCode}`,
    };
  }
}

export async function downloadTemplates(year, token, isExtended) {
  try {
    const response = await fetch(
      `/api/downloadtemplates?year=${year}&isextended=${isExtended}`,
      {
        headers: { Authorization: bearerAuth(token) },
      }
    );
    if (!response.ok) {
      return {
        error: `Misslyckades att ladda ner zip-fil för år ${year}`,
      };
    }
    return response;
  } catch (e) {
    return {
      error: `Misslyckades att ladda ner zip-fil för år ${year}`,
    };
  }
}

export async function downloadArchiveFiles(queryObject, token) {
  try {
    const END_POINT = '/api/downloadarchivefiles';
    const query = convertToQuery(queryObject);
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    if (!response.ok) {
      return {
        error: `Misslyckades att ladda ner arkivfil för år ${queryObject.year}`,
      };
    }
    return response;
  } catch (e) {
    return {
      error: `Misslyckades att ladda ner arkivfil för år ${queryObject.year}`,
    };
  }
}
