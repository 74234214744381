import React, { useEffect, useRef } from 'react';
import { TextInput } from '@aurora/widgets-react';
import './SearchBar.css';
import PropTypes from 'prop-types';
import { useStores } from '../../hooks/useStores';
import { ENTITY, SELECTED_CONTENT } from '../../utils/enum';
import { SearchOutline, CloseOutline } from '@aurora/icons-react';
import {
  isInactive,
  emptyNetwork,
  emptyDocument,
  generateArrayOfYears,
} from '../../utils/funcs';
import { observer } from 'mobx-react-lite';
import { getYear } from 'date-fns';

const NO_RESULT = {
  highlight: false,
  noIcon: true,
  name: 'Inga träffar',
};

const LOADING = {
  highlight: false,
  noIcon: true,
  name: 'Laddar ...',
};

const ERROR = {
  highlight: false,
  noIcon: true,
  name: '',
};

const SearchBar = observer(
  ({
    setSelectedContent = null,
    setSearchResults,
    searchEntity,
    placeholder,
    searchTerm,
    setSearchTerm,
    label,
    filterValue,
    setYears = null,
  }) => {
    const {
      stationStore,
      measurementStore,
      userStore,
      organisationStore,
      uiStore,
      reportStore,
      networkStore,
      authStore,
      realtimeDataStore,
    } = useStores();
    const searchInputRef = useRef(null);

    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    };

    const handleClose = (st = '') => {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
      if (uiStore.formDirty) {
        uiStore.useNavigation = false;
        uiStore.triggerModal = true;
        setSearchTerm(st);
        return;
      }
      if (setSelectedContent !== null) {
        setSelectedContent(SELECTED_CONTENT.ALL);
        uiStore.setSelectedContent(SELECTED_CONTENT.ALL);
      }
      setSearchTerm(st);
      reportStore.clearMessages();
      if (searchEntity === ENTITY.STATIONS) {
        measurementStore.setSelectedMeasurement(null);
        stationStore.setStation(null);
      }
      if (searchEntity === ENTITY.STATIONS_OBSERVATIONS) {
        setYears(generateArrayOfYears(new Date().getFullYear(), 1959));
      }
      userStore.setSelectedUser(
        userStore.users.find((u) => {
          return u.id === authStore.loggedInUser.id;
        })
      );
      organisationStore.clearMessages();
      networkStore.setSelectedNetwork(emptyNetwork);
      networkStore.setSelectedDocument(emptyDocument(getYear(new Date())));
      networkStore.clearMessages();
      reportStore.setStationObservations(null);
      reportStore.setObservationsStatus([]);
      reportStore.setSelectedMetaData(null);
      reportStore.clearMessages();
      userStore.clearMessages();
      measurementStore.clearMessages();
      stationStore.clearMessages();
    };

    useEffect(() => {
      const setStationResult = (stations) => {
        return stations.filter(
          (s) =>
            (s.name &&
              s.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.natlStationCode &&
              s.natlStationCode
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.organisationName &&
              s.organisationName
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.beginTime &&
              s.beginTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.endTime &&
              s.endTime.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };
      const setMeasurementResult = () => {
        if (!stationStore.selectedStation) return [];
        return stationStore.selectedStation.measurements.filter(
          (s) =>
            (s.pollutantNotation &&
              s.pollutantNotation
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.observationId &&
              s.observationId
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.beginTime &&
              s.beginTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.endTime &&
              s.endTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.templateClassification &&
              s.templateClassification
                .toLowerCase()
                .includes(searchTerm.toLowerCase()))
        );
      };

      const setUserResult = () => {
        return userStore.users.filter(
          (s) =>
            (s.firstName &&
              s.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.lastName &&
              s.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.organisationName &&
              s.organisationName
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.email &&
              s.email.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };

      const setRealtimeDataResult = () => {
        return realtimeDataStore.realtimeData.filter(
          (r) =>
            (r.stationname &&
              r.stationname.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (r.observationId &&
              r.observationId
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (r.pollutantNotation &&
              r.pollutantNotation
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (r.lastMeasurementValue &&
              r.lastMeasurementValue
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (r.lastMeasurementTimestamp &&
              r.lastMeasurementTimestamp
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (r.resolution &&
              r.resolution.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };

      const setOrganisationResult = () => {
        return organisationStore.organisations.filter(
          (s) =>
            (s.organisationName &&
              s.organisationName
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.organisationType &&
              s.organisationType
                .toLowerCase()
                .includes(searchTerm.toLowerCase()))
        );
      };
      const setNetworkResult = () => {
        return networkStore.networks.filter(
          (s) =>
            (s.network &&
              s.network.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.responsibleParty &&
              s.responsibleParty
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.type && s.type.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };
      const setStationsObservationsResult = () => {
        if (!reportStore.stationsObservations) {
          return [];
        }
        return reportStore.stationsObservations.filter(
          (s) =>
            (s.name &&
              s.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (s.natlStationCode &&
              s.natlStationCode
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.pollutants &&
              s.pollutants.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };
      const setObservationsStatusResult = () => {
        if (!reportStore.selectedStationObservations) return [];
        return reportStore.observationsStatus.filter(
          (s) =>
            (s.pollutantNotation &&
              s.pollutantNotation
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (s.observationId &&
              s.observationId
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()))
        );
      };

      const filterData = (endTime) => {
        if (filterValue === 0) return true;
        if (filterValue === 6) {
          return !isInactive(endTime);
        }
        if (filterValue === 5) {
          return isInactive(endTime);
        }
      };
      const setMeasuringSearchResult = () => {
        if (!stationStore.selectedStation) return [];
        return setSearchResults(stationStore.selectedStation.measurements);
      };
      const fetchData = async () => {
        let array = [LOADING];
        setSearchResults(array);
        let result = null;
        switch (searchEntity) {
          case ENTITY.STATIONS:
            result = setStationResult(stationStore.stations, filterValue);
            result = [...result].filter((station) => {
              return filterData(station.endTime);
            });
            break;
          case ENTITY.META:
            result = setStationResult(stationStore.stations);
            break;
          case ENTITY.MEASUREMENTS:
            result = setMeasurementResult();
            result = [...result].filter((m) => {
              return filterData(m.endTime);
            });
            break;
          case ENTITY.USERS:
            result = setUserResult();
            break;
          case ENTITY.ORGANISATIONS:
            result = setOrganisationResult();
            break;
          case ENTITY.NETWORKS:
            result = setNetworkResult();
            break;
          case ENTITY.STATIONS_OBSERVATIONS:
            result = setStationsObservationsResult();
            break;
          case ENTITY.OBSERVATIONS_STATUS:
            result = setObservationsStatusResult();
            break;
          case ENTITY.REALTIMEDATA:
            result = setRealtimeDataResult();
            break;
          default:
            return;
        }
        if (result.length === 0) {
          array = [NO_RESULT];
          setSearchResults(array);
        } else if (result.error) {
          ERROR.name = result.error;
          array = [ERROR];
          setSearchResults(array);
        } else {
          setSearchResults(result);
        }
      };
      if (searchTerm && searchTerm !== '') {
        fetchData();
      }
      if (searchTerm === '') {
        switch (searchEntity) {
          case ENTITY.STATIONS:
            setSearchResults(
              [...stationStore.stations].filter((station) => {
                return filterData(station.endTime);
              })
            );
            break;
          case ENTITY.MEASUREMENTS:
            setMeasuringSearchResult();
            break;
          case ENTITY.USERS:
            setSearchResults(userStore.users);
            break;
          case ENTITY.ORGANISATIONS:
            setSearchResults(organisationStore.organisations);
            break;
          case ENTITY.NETWORKS:
            setSearchResults(networkStore.networks);
            break;
          case ENTITY.STATIONS_OBSERVATIONS:
            setSearchResults(reportStore.stationsObservations || []);
            break;
          case ENTITY.OBSERVATIONS_STATUS:
            setSearchResults(reportStore.observationsStatus);
            break;
          case ENTITY.REALTIMEDATA:
            setSearchResults(realtimeDataStore.realtimeData);
            break;
          default:
            return;
        }
      }

      if (
        searchEntity === ENTITY.STATIONS &&
        (searchTerm || searchTerm === '')
      ) {
        if (searchInputRef.current) {
          searchInputRef.current.value = searchTerm;
          if (
            stationStore.selectedStation &&
            stationStore.selectedStation.natlStationCode
          ) {
            setSearchResults([stationStore.selectedStation]);
          }
        }
      }
    }, [
      searchTerm,
      searchEntity,
      setSearchResults,
      stationStore.selectedStation,
      stationStore.stations,
      userStore.users,
      organisationStore.organisations,
      reportStore.stationsObservations,
      reportStore.selectedStationObservations,
      reportStore.observationsStatus,
      filterValue,
      organisationStore.networks,
      networkStore.networks,
      realtimeDataStore.realtimeData,
    ]);

    const handleSearch = (e) => {
      const value = e.target.value;
      setSearchTerm(value);
    };

    return (
      <>
        <TextInput
          label={label}
          style={{ position: 'relative' }}
          ref={searchInputRef}
          id="search-input"
          name="search-input"
          placeholder={placeholder}
          onChange={handleSearch}
          value={searchTerm}
          icon={
            <>
              <CloseOutline
                className={
                  searchEntity === ENTITY.USERS
                    ? 'close-user-icon'
                    : 'close-icon'
                }
                style={{
                  backgroundColor:
                    searchEntity === ENTITY.STATIONS &&
                    stationStore.selectedStation
                      ? '#ebeef0'
                      : '#ffffff',
                }}
                size="m"
                aria-label="Avbryt sökning"
                tabIndex="0"
                onClick={() => {
                  uiStore.setCreatedStationSearchResult([]);
                  if (searchEntity === ENTITY.OBSERVATIONS_STATUS) {
                    if (searchInputRef.current) {
                      searchInputRef.current.value = '';
                    }
                    setSearchTerm('');
                  } else if (searchEntity === ENTITY.STATIONS_OBSERVATIONS) {
                    handleClose(
                      searchTerm.substring(0, searchTerm.indexOf('-') - 1)
                    );
                  } else {
                    handleClose();
                  }
                }}
                onKeyDown={handleKeyDown}
              />
              <SearchOutline
                style={{ zIndex: uiStore.triggerModal ? '1' : '1000000' }}
                className="search-icon"
                size="m"
              />
            </>
          }
          disabled={
            searchEntity === ENTITY.STATIONS && stationStore.selectedStation
          }
        />
      </>
    );
  }
);

SearchBar.propTypes = {
  setSelectedContent: PropTypes.func,
  setSearchResults: PropTypes.func,
  searchEntity: PropTypes.string,
  header: PropTypes.string,
  placeholder: PropTypes.string,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  label: PropTypes.string,
  filterValue: PropTypes.number,
};

export default SearchBar;
