import React from 'react';
import PropTypes from 'prop-types';
import './SideMenu.css';

const MenuItem = ({
  text,
  icon,
  onClick,
  disabled = false,
  divider = false,
  selected = false,
  isQuickLink = false,
  isStationMenu = false,
}) => {
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      onClick();
    }
  };

  return (
    <div
      tabIndex={disabled ? -1 : 0}
      role="button"
      aria-current={selected}
      aria-selected={selected}
      className={`side-menu--item
      ${divider ? 'divider' : ''}
      ${!icon ? 'indent' : ''}
      ${disabled ? 'disabled' : ''}
      ${selected ? 'selected' : ''}
      ${isQuickLink ? 'quick-link' : ''}
      ${isStationMenu ? 'station-menu' : ''}`}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      {icon}
      <span className="menu-item--text">{text}</span>
    </div>
  );
};

MenuItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  selected: PropTypes.bool,
  isQuickLink: PropTypes.bool,
  isStationMenu: PropTypes.bool,
};

export default MenuItem;
