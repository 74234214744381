import React from 'react';
import { H1 } from '@aurora/widgets-react';
import { InformationOutline } from '@aurora/icons-react';
import PropTypes from 'prop-types';
import './AppLoading.css';

const AppLoading = ({ message }) => {
  return (
    <div id="loading-app-container">
      <InformationOutline size="m" className="loading-app-container--icon" />
      <H1>{message || 'Laddar...'}</H1>
    </div>
  );
};

AppLoading.propTypes = {
  message: PropTypes.string,
};

export default AppLoading;
