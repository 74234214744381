import React from 'react';
import { H1 } from '@aurora/widgets-react';
import { HelpOutline32 } from '@aurora/icons-react';
import './NotFound.css';

const NotFoundPage = () => {
  return (
    <div id="page-not-found">
      <HelpOutline32 className="not-found-icon" />
      <H1>Sidan kunde inte hittas</H1>
    </div>
  );
};

export default NotFoundPage;
