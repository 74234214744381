import React from 'react';
import PropTypes from 'prop-types';

const RealTimeText = ({ pageType }) => {
  return (
    <>
      {pageType === 'status' && (
        <span>
          Här kan du se statusen för de kontinuerliga mätningar som rapporterar
          (eller som ska rapportera) realtidsdata till datavärden. Tabellen
          visar det senaste mätvärdet som rapporterades för alla mätningar som
          är aktiva (dvs. ej avslutade) klassificerade som kontinuerliga
          mätningar och som mäter med ett automatiskt mätinstrument.
          <br />
          <br />
          Ser du mätstationer/mätningar i listan som inte längre är aktiva? I så
          fall behöver du sätta ett slutdatum för mätstationen/mätningarna under
          fliken ”Stationer och mätningar”.
          <br />
          <br />
        </span>
      )}
    </>
  );
};

RealTimeText.propTypes = {
  pageType: PropTypes.string,
};

export default RealTimeText;
