import * as Yup from 'yup';
import { SELECTEDTYPES } from '../../utils/enum';
import { isAfterDate, isBeforeDate } from '../../utils/funcs';

export const stationSchema = (selectedStation) => {
  const isValidOrgname = (orgname, persistedOrgname) => {
    if (orgname !== '' && orgname !== persistedOrgname) {
      return false;
    } else {
      return persistedOrgname || persistedOrgname === '';
    }
  };

  return Yup.object().shape({
    name: Yup.string()
      .nullable()
      .max(50, 'Namn får inte vara längre än 50 bokstäver')
      .required('Namn måste anges'),
    organisationName: Yup.string()
      .nullable()
      .min(0, 'Ansvarig organisation för mätning måste anges')
      .required('Ansvarig organisation för mätning måste anges'),
    latitude: Yup.number()
      .nullable()
      .max(10000000, 'N-koordinat måste vara ett tal mellan 0 och 10000000')
      .min(0, 'N-koordinat måste vara ett tal mellan 0 och 10000000')
      .when('organisationName', {
        is: (orgName) => {
          return isValidOrgname(orgName, selectedStation.organisationName);
        },
        then: Yup.number().nullable().required('N-koordinat måste anges'),
      }),
    longitude: Yup.number()
      .nullable()
      .max(10000000, 'E-koordinat måste vara ett tal mellan 0 och 10000000')
      .min(0, 'E-koordinat måste vara ett tal mellan 0 och 10000000')
      .when('organisationName', {
        is: (orgname) => {
          return isValidOrgname(orgname, selectedStation.organisationName);
        },
        then: Yup.number().nullable().required('E-koordinat måste anges'),
      })
      .when('srs', {
        is: (srsVal) => {
          return srsVal != SELECTEDTYPES.SWEREF99;
        },
        then: Yup.number()
          .nullable()
          .when('organisationName', {
            is: (orgVal) => {
              return (
                !orgVal ||
                (orgVal && orgVal === selectedStation.organisationName)
              );
            },
            then: Yup.number()
              .nullable()
              .test({
                message: 'E-koordnitat får enbart innehålla 6 siffror',
                test: function (value) {
                  if (!value) return false;
                  const isValid = value.toString().length >= 6;
                  return isValid;
                },
              }),
          }),
      }),
    srs: Yup.string()
      .nullable()
      .min(0, 'Koordinatsystem måste anges')
      .required('Koordinatsystem måste anges'),
    beginTime: Yup.string()
      .nullable()
      .required('Startdatum måste anges')
      .test({
        message:
          'Startdatum får inte vara efter någon av stationens mätningars startdatum',
        test: function (enteredStationBeginTime) {
          if (!selectedStation.measurements) return true;
          const isValid = !selectedStation.measurements.some((measurement) => {
            return isAfterDate(enteredStationBeginTime, measurement.beginTime);
          });
          return isValid;
        },
      }),
    endTime: Yup.string()
      .nullable()
      .test({
        message:
          'Slutdatum kan inte anges om någon av stationens mätningar saknar slutdatum',
        test: function (value) {
          if (
            !selectedStation.measurements ||
            !value ||
            value === '' ||
            selectedStation.endTime ||
            selectedStation.endTime !== ''
          )
            return true;
          const isValid = !selectedStation.measurements.some((measurement) => {
            return !measurement.endTime;
          });
          return isValid;
        },
      })
      .test({
        message:
          'Slutdatum får inte vara tidigare än någon av stationen mätningars slutdatum',
        test: function (enteredStationEndTime) {
          if (!selectedStation.measurements) return true;
          const isValid = !selectedStation.measurements.some((measurement) => {
            return isBeforeDate(enteredStationEndTime, measurement.endTime);
          });
          return isValid;
        },
      }),
  });
};
export const extendedSchema = Yup.object().shape({
  extendedInformation: Yup.object().shape({
    stationClassification: Yup.string()
      .nullable()
      .min(0, 'Typ av mätstation måste anges')
      .required('Typ av mätstation måste anges'),
    areaClassification: Yup.string()
      .nullable()
      .min(0, 'Typ av område måste anges')
      .required('Typ av område måste anges'),
    urbanName: Yup.string()
      .nullable()
      .when('areaClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.URBAN || val === SELECTEDTYPES.SUBURBAN;
        },
        then: Yup.string()
          .nullable()
          .max(50, 'Namn får inte vara längre än 50 bokstäver')
          .required('Namn måste anges'),
      }),
    streetAddress: Yup.string()
      .nullable()
      .when('areaClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.URBAN || val === SELECTEDTYPES.SUBURBAN;
        },
        then: Yup.string()
          .nullable()
          .max(50, 'Namn får inte vara längre än 50 bokstäver')
          .required('Namn måste anges'),
      }),
    municipality: Yup.string()
      .nullable()
      .min(0, 'Kommun måste anges')
      .required('Kommun måste anges'),
    altitude: Yup.number()
      .nullable()
      .typeError('Höjd över havet måste vara ett nummer')
      .required('Höjd över havet måste anges'),
    streetWidth: Yup.number()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.number()
          .nullable()
          .typeError('Gatans bredd måste vara ett nummer')
          .required('Gatans bredd måste anges')
          .min(0, 'Gatans bredd får inte vara negativt'),
      }),
    distanceJunction: Yup.number()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.number()
          .nullable()
          .typeError('Avstånd vägkorsning/trafikplats måste vara ett nummer')
          .required('Avstånd vägkorsning/trafikplats måste anges')
          .min(0, 'Avstånd vägkorsning/trafikplats får inte vara negativt'),
      }),
    buildings: Yup.string()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.string()
          .nullable()
          .required('Omgivande bebyggelse måste anges'),
      }),
    heightFacades: Yup.number()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.number()
          .nullable()
          .typeError('Genomsnittlig fasadhöjd måste vara ett nummer')
          .required('Genomsnittlig fasadhöjd måste anges')
          .min(0, 'Genomsnittlig fasadhöjd får inte vara negativt'),
      }),
    trafficVolume: Yup.number()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.number()
          .nullable()
          .typeError('Uppskattad trafikvolym måste vara ett nummer')
          .required('Uppskattad trafikvolym måste anges')
          .min(0, 'Uppskattad trafikvolym får inte vara negativt'),
      }),
    heavyDutyFraction: Yup.number()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.number()
          .nullable()
          .typeError('Andel tung trafik (%) måste vara ett nummer')
          .required('Andel tung trafik (%) måste anges')
          .min(0, 'Andel tung trafik (%) får inte vara negativt'),
      }),
    trafficSpeed: Yup.string()
      .nullable()
      .when('stationClassification', {
        is: (val) => {
          return val === SELECTEDTYPES.TRAFFIC;
        },
        then: Yup.string().nullable().required('Hastighet måste anges'),
      }),
    network: Yup.string()
      .nullable()
      .min(0, 'Mätnätverk måste anges')
      .required('Mätnätverk måste anges'),
  }),
});
