import { bearerAuth } from '../utils/funcs';

export async function updateMeasurement(measurement, url, token) {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(measurement),
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att uppdatera aktuell mätning',
      };
    }
  } catch (e) {
    return { error: 'Det gick inte att uppdatera aktuell mätning' };
  }
}

export async function insertMeasurement(measurement, stationid, token) {
  try {
    const response = await fetch(`/api/measurement?station=${stationid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(measurement),
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att skapa aktuell mätning',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att skapa aktuell mätning' };
  }
}

export async function publish(id, published, token) {
  try {
    const response = await fetch(
      `/api/measurement/publish/${id}?published=${published}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: bearerAuth(token),
        },
      }
    );
    if (!response.ok) {
      return {
        error: 'Det gick inte att publicera aktuell mätning',
      };
    }
  } catch (e) {
    return { error: 'Det gick inte att publicera aktuell mätning' };
  }
}

export async function deleteMeasurement(id, token) {
  try {
    const response = await fetch(`/api/measurement/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att ta bort aktuell mätning',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att ta bort aktuell mätning' };
  }
}
