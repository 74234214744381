import { bearerAuth, convertToQuery } from '../utils/funcs';

export async function getUsers(token) {
  try {
    const response = await fetch(`/api/users`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av användare misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av användare misslyckades' };
  }
}

export async function getUsersByOrganisationName(token, queryObject) {
  try {
    const END_POINT = '/api/users';
    const query = convertToQuery(queryObject);
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av användare misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av användare misslyckades' };
  }
}

export async function saveUser(user, token, isChangingRole) {
  try {
    let formData = new FormData();
    formData.append('user', user);
    formData.append('isChangingRole', isChangingRole);
    const response = await fetch(
      `/api/user?ischangingrole=${isChangingRole.toString()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: bearerAuth(token),
        },
        body: JSON.stringify(user),
      }
    );
    if (!response.ok) {
      return {
        error: `Det gick inte att ${
          user.id ? 'uppdatera' : 'skapa'
        } aktuell användare`,
      };
    }
    const id = await response.json();
    const END_POINT = '/api/suborganisations';
    const query = convertToQuery({ userid: id });
    const ids = user.subOrganisations.map((suborganisation) => {
      return suborganisation.id;
    });
    const response2 = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(ids),
    });
    if (!response2.ok) {
      return {
        error: `Det gick inte att ${
          user.id ? 'uppdatera' : 'skapa'
        } aktuell användares subOrganisationer`,
      };
    }
  } catch (e) {
    return {
      error: `Det gick inte att ${
        user.id ? 'uppdatera' : 'skapa'
      } aktuell användare`,
    };
  }
}

export async function deleteUser(id, token) {
  try {
    const response = await fetch(`/api/user/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        headers: { Authorization: bearerAuth(token) },
      },
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att ta bort aktuell användare',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att ta bort aktuell användare' };
  }
}
