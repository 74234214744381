import { bearerAuth } from '../utils/funcs';

export async function getUser(token) {
  try {
    const response = await fetch(`/api/user`, {
      headers: { Authorization: bearerAuth(token) },
    });
    if (response.status === 401 || response.status === 403) {
      return {
        unauthorized: 'Du är inte behörig för applikationen',
      };
    }
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av inloggad användare misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av inloggad användare misslyckades' };
  }
}
