import React from 'react';
import propTypes from 'prop-types';
import './AuditContainer.css';
import { H1, Body1 } from '@aurora/widgets-react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import { USER_ROLE } from '../../../utils/enum';
import AuditText from '../../../AuditText';
import LinkMenu from '../../LinkMenu';
import MenuItem from '../../SideMenu/MenuItem';

const AuditContainer = observer(({ header, type, children }) => {
  const { authStore, uiStore } = useStores();
  const currentPage = window.location.pathname;

  return (
    <>
      {authStore.loggedInUser &&
        authStore.loggedInUser.role === USER_ROLE.SUPERUSER && (
          <div id="audit-container">
            <H1 tabIndex="0">{header}</H1>
            <Body1 tabIndex="0" className="text">
              <AuditText pageType={type} />
            </Body1>
            <LinkMenu maxwidth={45}>
              <MenuItem
                text="Databastabeller"
                onClick={() => {
                  uiStore.navigate('/rapportering/audit/databastabeller');
                }}
                selected={currentPage === '/rapportering/audit/databastabeller'}
              />
              <MenuItem
                text="Statistikberäkningar"
                onClick={() => {
                  uiStore.navigate('/rapportering/audit/statistikberakningar');
                }}
                selected={
                  currentPage === '/rapportering/audit/statistikberakningar'
                }
              />
            </LinkMenu>
            <hr />
            {children}
          </div>
        )}
    </>
  );
});

AuditContainer.propTypes = {
  header: propTypes.node,
  type: propTypes.string,
  children: propTypes.node,
};

export default AuditContainer;
