import React from 'react';
import './Grid.css';
import { Subtitle2, Button, Select } from '@aurora/widgets-react';
import {
  ChevronLeftOutline,
  ChevronRightOutline,
  DoubleChevronLeftOutline,
  DoubleChevronRightOutline,
} from '@aurora/icons-react';
import PropTypes from 'prop-types';

const Pagination = ({
  pageSize,
  setPageSize,
  pageCount,
  pageIndex,
  pageOptions,
  total,
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  previousPage,
  startRow,
  endRow,
}) => {
  const pageList = [
    { key: 50, value: '50' },
    { key: 100, value: '100' },
    { key: 150, value: '150' },
    { key: 200, value: '200' },
    { key: 400, value: '400' },
  ];

  const isFirstOrLastPage =
    (pageIndex === 0 && total < endRow) || pageOptions.length === pageIndex + 1;

  const pageInfo = isFirstOrLastPage
    ? `${startRow + 1} - ${total} av ${total}`
    : `${startRow + 1} - ${endRow} av ${total}`;

  return (
    <>
      <div className="no-margin flex-row" id="grid-pagination">
        <div className="middle-align flex-item">
          <Subtitle2 tabIndex="0" id="pageInfo-txt">
            {pageInfo}
          </Subtitle2>
        </div>
        <div className="flex-row flex-item pagination-select">
          <Select
            label="Visa"
            className="page-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            multiple={false}
            list={pageList}
            required={true}
            native
          />
        </div>
        <div className="right-align">
          <Button
            id="gotoFirstPage-btn"
            className="pagination-buttons"
            onClick={() => gotoPage(0)}
            icon={<DoubleChevronLeftOutline className="table-button-icon" />}
            disabled={!canPreviousPage}
            aria-label="gå till första sidan"
            label={' '}
          ></Button>
          <Button
            id="gotoPrevPage-btn"
            className="pagination-buttons"
            onClick={() => previousPage()}
            icon={<ChevronLeftOutline className="table-button-icon" />}
            disabled={!canPreviousPage}
            aria-label="gå till föregående sida"
            label={' '}
          ></Button>
          <Button
            id="gotoNextPage-btn"
            className="pagination-buttons"
            onClick={() => nextPage()}
            icon={<ChevronRightOutline className="table-button-icon" />}
            disabled={!canNextPage}
            aria-label="gå till nästa sida"
            label={' '}
          ></Button>
          <Button
            id="gotoLastPage-btn"
            className="pagination-buttons"
            onClick={() => gotoPage(pageCount - 1)}
            icon={<DoubleChevronRightOutline className="table-button-icon" />}
            disabled={!canNextPage}
            aria-label="gå till sista sidan"
            label={' '}
          ></Button>
        </div>
      </div>
    </>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  total: PropTypes.number,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  startRow: PropTypes.number,
  endRow: PropTypes.number,
  gridEntity: PropTypes.string,
};

export default Pagination;
