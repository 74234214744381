import React, { useState } from 'react';
import propTypes from 'prop-types';
import './ReportContainer.css';
import { H1, Body1, Button } from '@aurora/widgets-react';
import LinkMenu from '../../LinkMenu';
import MenuItem from '../../SideMenu/MenuItem';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import StationList from '../ReportStationList';
import { DownloadOutline } from '@aurora/icons-react';
import { useKeycloak } from '@react-keycloak/web';
import Loading from '../../Loading';
import ReportText from '../../../ReportText';

const ReportContainer = observer(({ header, type, children }) => {
  const { keycloak } = useKeycloak();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const currentPage = window.location.pathname;
  const { uiStore, reportStore } = useStores();
  const showList =
    currentPage === '/rapportering/rapportera/oversikt' ||
    currentPage === '/rapportering/rapportera/metadata';

  const hideTable =
    showList &&
    reportStore.selectedStationObservations &&
    !uiStore.triggeredFromDownload;

  return (
    <>
      {loading && (
        <div className="loading-flex-row">
          <span className="loading-center-align">
            <Loading isLoading={loading} text="Loading all templates" />
          </span>
        </div>
      )}
      {!loading && (
        <div id="report-container">
          <div className="flex-row">
            <H1 tabIndex="0">{header}</H1>
            {currentPage === '/rapportering/rapportera/oversikt' && (
              <Button
                className="right-align download-button"
                variant={Button.variants.secondary}
                icon={<DownloadOutline />}
                label="Hämta alla mallar"
                onClick={async () => {
                  setLoading(true);
                  await reportStore.downloadTemplates(
                    reportStore.selectedYear,
                    keycloak.token,
                    false
                  );
                  await reportStore.downloadTemplates(
                    reportStore.selectedYear,
                    keycloak.token,
                    true
                  );
                  setLoading(false);
                }}
              >
                Hämta alla mallar
              </Button>
            )}
          </div>
          <Body1 tabIndex="0" className="text">
            <ReportText pageType={type} />
          </Body1>
          <LinkMenu maxwidth={45}>
            <MenuItem
              text="Översikt och mallar"
              onClick={() => {
                uiStore.navigate('/rapportering/rapportera/oversikt');
              }}
              isStationMenu
              selected={currentPage === '/rapportering/rapportera/oversikt'}
            />
            <MenuItem
              text="Ladda upp mätdata"
              onClick={() => {
                uiStore.navigate('/rapportering/rapportera/laddaupp');
              }}
              isStationMenu
              selected={currentPage === '/rapportering/rapportera/laddaupp'}
            />
            <MenuItem
              text="Årliga metadata"
              onClick={() => {
                uiStore.navigate('/rapportering/rapportera/metadata');
              }}
              isStationMenu
              selected={currentPage === '/rapportering/rapportera/metadata'}
            />
          </LinkMenu>
          <hr />
          {showList && (
            <StationList
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              hide={hideTable}
            />
          )}
          {children}
        </div>
      )}
    </>
  );
});

ReportContainer.propTypes = {
  header: propTypes.node,
  type: propTypes.string,
  children: propTypes.node,
};

export default ReportContainer;
