import React from 'react';
import PropTypes from 'prop-types';

const ReportText = ({ pageType }) => {
  return (
    <>
      {pageType === 'overview' && (
        <span>
          Här kan du se status för dina rapporteringar av mätdata och ladda ner
          mallar för rapportering. Förra året är alltid förvalt, men även
          tidigare års rapporteringar går att få fram genom att ändra under
          ”Avser år”.
          <br />
          <br />
          Status visas både för rapporteringen av mätdata och de årliga
          uppgifterna (metadata) kopplade till mätresultaten, bl.a.
          tidstäckning, osäkerhet, kalibrering osv.
          <br />
          <br /> Om du klickar på en mätstation kan du också se delstatus för
          respektive förorening, och separat för rapporteringen av mätdata och
          metadata för den föroreningen.
          <br />
          <br /> Har du sett mätstationer/mätningar i listan som inte var aktiva
          under det valda året? I så fall behöver du sätta ett slutdatum för
          mätstationen/mätningarna under fliken Stationer och mätningar.
          <br />
          <br />
        </span>
      )}
      {pageType === 'reportMeasurement' && (
        <span>
          Här laddar du upp dina ifyllda rapporteringsfiler (mätdata)! Var noga
          med att använda rätt mall! Mallar laddas ner på fliken ”Översikt och
          mallar”
          <br />
          <br /> Du kan ladda upp en fil i taget eller flera samtidigt. Det görs
          först en automatisk validering för att säkerställa att filerna är ok.
          Filer som innehåller fel måste åtgärdas innan de rapporteras. Godkända
          filer kan rapporteras in, medan filer med varningar ska åtgärdas eller
          bekräftas innan de kan rapporteras in.
          <br />
          <br /> Du får ett kvitto på genomförda rapporteringar. Kom ihåg att
          även rapportera årliga metadata för respektive mätning!
          <br />
          <br />
        </span>
      )}
      {pageType === 'reportMeta' && (
        <span>
          Har du rapporterat mätdata? Här kompletterar du rapporteringen med
          uppgifter (metadata) om datakvalitet (osäkerhet, tidstäckning och
          datafångst), korrigeringsfaktorer, mätningarnas syfte osv.
          <br />
          <br /> Sök först upp den mätstation som du vill rapportera metadata
          för. Klicka på mätstationen för att se vilka mätningar uppgifterna ska
          rapporteras för. Klicka därefter på en mätning för att få upp ett
          formulär som du sedan anger uppgifterna i.
          <br />
          <br />
          När du är klar med alla uppgifter, klickar du på{' '}
          <i>Skapa observationens metadata för år 2021</i> och därefter på
          <i> Rapportera in.</i> Om du vill spara uppgifterna som utkast för att
          kunna fortsätta redigera och slutföra i ett senare skede, vänta med
          att klicka på <i>Rapportera in.</i>
          <br />
          <br /> När metadata har rapporterats in skickas de först för
          granskning av Referenslaboratoriet för luftkvalitet tillsammans med
          mätdata. Status blir därför ”Granskas”. Reflab kontaktar rapportören
          vid eventuella frågor. När granskningen är klar och uppgifterna är
          godkända ändras statusen för rapporteringen till ”Klar” för respektive
          förorening och station.
          <br />
          <br />
        </span>
      )}
    </>
  );
};

ReportText.propTypes = {
  pageType: PropTypes.string,
};

export default ReportText;
