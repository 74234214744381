import React from 'react';
import './ButtonGroup.css';
import { Button } from '@aurora/widgets-react';
import propTypes from 'prop-types';
import { useStores } from '../../hooks/useStores';

const ButtonGroup = ({
  selectedContent,
  setSelectedContent,
  disabled = false,
  buttons,
}) => {
  const { uiStore } = useStores();
  return (
    <div id="button-group">
      {buttons.map((SELECTED_CONTENT, index) => {
        return (
          <Button
            key={`btn ${index}`}
            className={`btn ${
              disabled
                ? ''
                : selectedContent.title === SELECTED_CONTENT.title
                ? 'selected'
                : 'not-selected'
            }`}
            onClick={() => {
              setSelectedContent(SELECTED_CONTENT);
              uiStore.setSelectedContent(SELECTED_CONTENT);
            }}
            label={SELECTED_CONTENT.title}
            disabled={disabled}
          >
            {SELECTED_CONTENT.title}
          </Button>
        );
      })}
    </div>
  );
};

ButtonGroup.propTypes = {
  selectedContent: propTypes.object,
  setSelectedContent: propTypes.func,
  disabled: propTypes.bool,
  buttons: propTypes.array,
};

export default ButtonGroup;
