import {
  stationSchema,
  extendedSchema,
} from '../components/Form/stationSchemas';
import {
  measurementSchema,
  inletSchema,
  equipmentSchema,
  emissionSourcesSchema,
} from '../components/Form/measurementSchemas';
import { metaSchema } from '../components/Form/reportSchemas';
import { isBefore, isAfter, getYear, subYears, addYears } from 'date-fns';

export const convertToSelectList = (list, defaultValue) => {
  if (!list) {
    return [
      {
        key: '',
        value: defaultValue || 'Välj alternativ',
      },
    ];
  }
  const keyValues = list.map((item) => {
    return {
      key: item.label,
      value: item.label || '',
    };
  });
  keyValues.unshift({
    key: '',
    value: defaultValue || 'Välj alternativ',
  });
  return keyValues;
};

export const bearerAuth = (token) => {
  return `Bearer ${token}`;
};

export const validateEntity = async (schema, entity, errorBuilder) => {
  if (!errorBuilder) {
    errorBuilder = '';
  }
  return schema
    .validate(entity, { abortEarly: false })
    .then(() => {
      return errorBuilder !== '' ? errorBuilder : '';
    })
    .catch((err) => {
      const errorArr = [];
      if (err && err.errors) {
        err.errors.forEach((e) => {
          if (e.includes('null')) return;
          if (errorArr.length === 0 && errorBuilder === '') {
            errorArr.push(
              'Åtgärda följande valideringsfel och spara formuläret innan publicering/avpublicering.\n'
            );
          }
          errorArr.push(e);
        });
        return errorArr.length === 0
          ? ''
          : `${errorBuilder} \n ${errorArr.join('\n')}`;
      }
    });
};

export const convertToQuery = (queryObject) => {
  if (!queryObject) return null;
  return Object.keys(queryObject)
    .map((key) => `${key}=${queryObject[key]}`)
    .join('&');
};

export const generateArrayOfYears = (end, start) => {
  let years = [];

  for (let i = end; i >= start; i--) {
    years.push({
      key: i,
      value: i,
    });
  }
  return years;
};

export const validateStation = async (station, selectedStation) => {
  const stationErrors = await validateEntity(
    stationSchema(selectedStation),
    station,
    ''
  );
  const extendedErrors = await validateEntity(
    extendedSchema,
    station,
    stationErrors
  );

  return extendedErrors;
};

export const validateMetadata = async (metaData) => {
  const metadataErrors = await validateEntity(
    metaSchema(metaData),
    metaData,
    ''
  );

  return metadataErrors;
};

export const isInactive = (date) => {
  if (!date || date === 'null' || date === '') return false;
  return isBefore(new Date(date), new Date());
};

export const isBeforeDate = (date, compareDate) => {
  if (!date || !compareDate) return false;
  if (date === 'null' || date === '') return false;
  return isBefore(new Date(date), new Date(compareDate));
};

export const isAfterDate = (date, compareDate) => {
  if (!date || !compareDate) return false;
  if (date === 'null' || date === '') return false;
  return isAfter(new Date(date), new Date(compareDate));
};

export const executeScrollToElement = (scrollElement) => {
  const element = scrollElement.current;
  if (element) {
    element.scrollIntoView();
  }
};

export const validateMeasurement = async (
  measurement,
  selectedStation,
  samplingEquipments,
  measurementEquipments,
  samplingMethods,
  measurementMethods
) => {
  const stationClassification = selectedStation
    ? selectedStation.extendedInformation.stationClassification
    : '';
  const measurementErrors = await validateEntity(
    measurementSchema(selectedStation),
    measurement,
    ''
  );
  const inletErrors = await validateEntity(
    inletSchema(stationClassification, selectedStation),
    measurement,
    measurementErrors
  );
  const emissionSourceErrors = await validateEntity(
    emissionSourcesSchema(stationClassification),
    measurement,
    inletErrors
  );
  const equipmentErrors = await validateEntity(
    equipmentSchema(
      samplingEquipments.map((x) => {
        return x.label;
      }),
      measurementEquipments.map((x) => {
        return x.label;
      }),
      samplingMethods.map((x) => {
        return x.label;
      }),
      measurementMethods.map((x) => {
        return x.label;
      })
    ),
    measurement,
    emissionSourceErrors
  );
  return equipmentErrors;
};

export const getDateArray = (start, end) => {
  const arr = [];
  let index = 0;
  while (start >= addYears(end, index)) {
    arr.push({
      key: getYear(subYears(new Date(), index)),
      value: getYear(subYears(new Date(), index)),
    });
    index++;
  }

  return arr;
};

export const convertNullPropsToEmptyString = (list) => {
  if (!list) return;
  Object.keys(list).forEach((property) => {
    if (list[property] === null) {
      list[property] = '';
    }
  });
};

export const urlError = 'Måste vara en giltig URL till ett dokument';

export const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-\\.]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\.([a-zA-Z0-9_])+)?$/gm;

export const emptyStation = {
  name: '',
  latitude: '',
  longitude: '',
  srs: 'SWEREF99 TM',
  natlStationCode: '',
  beginTime: '',
  endTime: '',
  organisationName: '',
  extendedInformation: {
    stationClassification: '',
    areaClassification: '',
    urbanName: '',
    streetAddress: '',
    municipality: '',
    mobile: false,
    altitude: '',
    streetWidth: '',
    distanceJunction: '',
    buildings: '',
    heightFacades: '',
    trafficSpeed: '',
    trafficVolume: '',
    heavyDutyFraction: '',
    localDispersion: '',
    regionalDispersion: '',
    meteorologicalParameters: '',
    network: '',
  },
};

export const emptyMeasurement = {
  observationId: '',
  pollutantNotation: '',
  beginTime: '',
  endTime: '',
  organisationName: '',
  performer: '',
  templateClassification: '',
  primaryObservationTime: '',
  extendedTemplate: false,
  inlet: {
    inletId: '',
    latitude: '',
    longitude: '',
    srs: 'SWEREF99 TM',
    inletHeight: '',
    kerbDistance: '',
    buildingDistance: '',
  },
  measurementProcess: {
    assessmentType: '',
    measurementType: '',
    measurementMethod: '',
    samplingMethod: '',
    analyticalTechnique: '',
    measurementEquipment: '',
    samplingEquipment: '',
    equivalenceDemonstrated: '',
    detectionLimit: '',
    duration: '',
    durationUnit: '',
    cadence: '',
    cadenceUnit: '',
    measuringLength: '',
    geographicalRepresentativeness: '',
    representativeness: '',
    documentationRepresentativeness: '',
  },
  emissionSources: {
    emissionSource: '',
    trafficEmissions: '',
    distanceStreet: '',
    heatingEmissions: '',
    distanceIndustry: '',
    industrialEmissions: '',
    otherInfluencingFactors: '',
  },
};

export const emptyUser = (organisationName) => {
  return {
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
    organisationName: organisationName || '',
    subOrganisations: [],
  };
};

export const emptyNetwork = {
  id: null,
  network: '',
  responsibleParty: '',
  type: '',
  documents: [],
};

export const emptyDocument = (year) => {
  return {
    year: year,
    controlStrategy: '',
    qualityProgram: '',
    programCoordinatedControl: '',
    report: '',
  };
};

export const emptyOrganisation = {
  organisationName: '',
  stad: '',
  organisationType: '',
  address: '',
  boxAddress: '',
  postcode: '',
  postalAddress: '',
  website: '',
  phone: '',
};

export const emptyStationObservations = {
  name: '',
  natlStationCode: '',
  pollutants: '',
  maxStatus: '',
};

export const emptyObservationMetaDatas = (selectedYear, assementType) => {
  return {
    year: selectedYear,
    measurementType: assementType,
    timeCoverage: '',
    uncertainty: '',
    detectionLimit: '',
    calibrationFrequency: '',
    calibration: '',
    correctionFactor: '',
    teomFunction: '',
    purpose: '',
    dqDocumentation: '',
    reasonMissingData: '',
    otherInfluencingFactors: '',
    quantity: '',
  };
};

export const auditTables = [
  'archive',
  'controlprocedure',
  'inlet',
  'network',
  'networkcontrolprocedure',
  'observation',
  'observationmetadata',
  'observationstatus',
  'relatedparty',
  'samplingpoint',
  'spprocess',
  'station',
  'user',
];
export const actions = [
  { action: 'Update', short: 'U' },
  { action: 'Insert', short: 'I' },
  { action: 'Delete', short: 'D' },
];
