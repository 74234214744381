import React, { useEffect, useState } from 'react';
import SideMenu from '../SideMenu';
import { AuroraRoot, Paragraph, Button, Modal } from '@aurora/widgets-react';
import propTypes from 'prop-types';
import CookieNotice from './CookieNotice';
import './AppRoot.css';
import Footer from '../Footer';
import Loading from '../Loading';
import Error from '../Error';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useKeycloak } from '@react-keycloak/web';
import { USER_ROLE } from '../../utils/enum';

const AppRoot = observer((props) => {
  const { keycloak, initialized } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const {
    stationStore,
    userStore,
    organisationStore,
    authStore,
    networkStore,
    realtimeDataStore,
  } = useStores();
  let content = '';

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(60);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  useEffect(() => {
    const initData = async () => {
      stationStore.setInitialErrorMessage(null);
      try {
        setLoading(true);
        await authStore.getUser(keycloak.token);
        if (authStore.loggedInUser) {
          await stationStore.getStations(keycloak.token);
          await organisationStore.getOrganisations(keycloak.token);
          organisationStore.setSelectedOrganisation(
            organisationStore.organisations.find((o) => {
              return (
                o.organisationName === authStore.loggedInUser.organisationName
              );
            })
          );
          if (
            authStore.loggedInUser &&
            (authStore.loggedInUser.role === USER_ROLE.GROUPUSER ||
              authStore.loggedInUser.role === USER_ROLE.GROUPADMIN ||
              authStore.loggedInUser.role === USER_ROLE.NATIONALSUPERVISOR)
          ) {
            await userStore.getUsersByOrganisationName(
              keycloak.token,
              authStore.loggedInUser.organisation,
              () => {}
            );
          } else if (authStore.loggedInUser) {
            await userStore.getUsers(keycloak.token, authStore.loggedInUser.id);
          }

          await networkStore.getNetworks(keycloak.token);
          await realtimeDataStore.getRealtimeData(keycloak.token);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    if (initialized) {
      if (keycloak.authenticated) {
        initData();
      } else {
        keycloak.login();
      }
    } else {
      setLoading(true);
    }
  }, [
    authStore,
    initialized,
    keycloak,
    networkStore,
    organisationStore,
    realtimeDataStore,
    stationStore,
    userStore,
  ]);

  if (initialized && keycloak.authenticated) {
    content = (
      <div className="wrapper">
        <div className="left">
          <SideMenu />
        </div>
        <div className="right">
          <div className="content">
            <Loading isLoading={loading} text="Loading data..." />
            <Error error={stationStore.initialErrorMessage} />
            {!stationStore.initialErrorMessage &&
              !loading &&
              stationStore.stations && <>{props.children}</>}
          </div>
          <Footer innerClass="logged-in" />
        </div>
      </div>
    );
  }

  return (
    <AuroraRoot>
      <div id="app-root">
        {authStore.unauthorizedMessage && (
          <Modal
            close={false}
            onClose={() => {
              keycloak.logout();
            }}
            onOpen={() => {}}
            buttons={
              <>
                <Button
                  label="Ok"
                  type="submit"
                  onClick={() => {
                    keycloak.logout();
                  }}
                  variant={Button.variants.primary}
                >
                  Ok
                </Button>
              </>
            }
            title="Obehörig"
            variant={Modal.variants.warning}
          >
            <Paragraph className="modal-text">
              {authStore.unauthorizedMessage}
            </Paragraph>
          </Modal>
        )}
        {authStore.loggedInUser && (
          <>
            {content}
            <CookieNotice />
          </>
        )}
      </div>
    </AuroraRoot>
  );
});

AppRoot.propTypes = {
  children: propTypes.node,
};

export default AppRoot;
