import { bearerAuth } from '../utils/funcs';

export async function getOrganisations(token) {
  try {
    const response = await fetch(`/api/organisations`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av organisationer misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av organisationer misslyckades' };
  }
}

export async function saveOrganisation(organisation, token) {
  try {
    const response = await fetch(`/api/organisation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(organisation),
    });
    if (!response.ok) {
      return {
        error: `Det gick inte att ${
          organisation.id ? 'uppdatera' : 'skapa'
        } aktuellt nätverk`,
      };
    }
  } catch (e) {
    return {
      error: `Det gick inte att ${
        organisation.id ? 'uppdatera' : 'skapa'
      } aktuellt nätverk`,
    };
  }
}

export async function deleteOrganisation(id, token) {
  try {
    const response = await fetch(`/api/organisation/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att ta bort aktuell organisation',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att ta bort aktuell organisation' };
  }
}
