import React, { useState, useCallback } from 'react';
import './Grid.css';
import TableMaker from './TableMaker';
import PropTypes from 'prop-types';

const Grid = ({
  gridData,
  onRowClick,
  reactTableHeader,
  gridEntity,
  // noPagination = false,
}) => {
  const columns = React.useMemo(() => {
    return reactTableHeader;
  }, [reactTableHeader]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [startRow, setStartRow] = useState(0);
  const [endRow, setEndRow] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, pageOptions }) => {
      setLoading(true);
      setStartRow(pageSize * pageIndex);
      setEndRow(pageSize * pageIndex + pageSize);
      pageOptions && pageOptions.length < pageIndex
        ? setControlledPageIndex(0)
        : setControlledPageIndex(pageIndex);
      setData(gridData.slice(startRow, endRow));
      setPageCount(Math.ceil(gridData.length / pageSize));
      setLoading(false);
    },
    [gridData, endRow, startRow]
  );

  return (
    <div id="grid">
      <TableMaker
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        controlledPageIndex={controlledPageIndex}
        gridData={gridData}
        rowInfo={onRowClick}
        startRow={startRow}
        endRow={endRow}
        gridEntity={gridEntity}
        //noPagination={noPagination}
      />
    </div>
  );
};

Grid.propTypes = {
  reactTableHeader: PropTypes.array,
  gridData: PropTypes.array,
  onRowClick: PropTypes.func,
  gridEntity: PropTypes.string,
  noPagination: PropTypes.bool,
};

export default Grid;
