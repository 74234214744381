import React from 'react';
import { Row, Col } from '@aurora/widgets-react';
import {
  RadarOutline,
  AdminOutline,
  DocumentOutline,
} from '@aurora/icons-react';
import './QuickLinks.css';
import SearchWithResult from '../SearchBar/SearchWithResult';
import MenuItem from '../SideMenu/MenuItem';
import { useStores } from '../../hooks/useStores';
import { USER_ROLE } from '../../utils/enum';
import { emptyStation } from '../../utils/funcs';

const QuickLinks = () => {
  const { uiStore, authStore, stationStore } = useStores();
  return (
    <div id="quick-links">
      <Row>
        <Col xs={12} className="header">
          <h1 tabIndex="0">Snabbvägar</h1>
        </Col>
      </Row>
      <Row className="search-bar">
        <Col xs={12}>
          <SearchWithResult />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="side-menu">
          <MenuItem
            icon={<RadarOutline />}
            text="Registrera ny mätstation"
            onClick={() => {
              uiStore.navigate('/rapportering/station');
              stationStore.setStation(emptyStation);
            }}
            isQuickLink
          />
          <MenuItem
            icon={<DocumentOutline />}
            text="Ladda ner rapporteringsmall"
            onClick={() => {
              uiStore.navigate('/rapportering/rapportera/oversikt');
            }}
            isQuickLink
          />
          <MenuItem
            icon={<AdminOutline />}
            text="Rapportera kontrollstrategi, kvalitetssäkringsprogram, mm"
            onClick={() => {
              uiStore.navigate('/rapportering/uppgifter/natverk');
            }}
            isQuickLink
          />
          {authStore.loggedInUser &&
            authStore.loggedInUser.role === USER_ROLE.SUPERUSER && (
              <MenuItem
                icon={<AdminOutline />}
                text="Hantera användare"
                onClick={() =>
                  uiStore.navigate('/rapportering/uppgifter/anvandare')
                }
                isQuickLink
              />
            )}
        </Col>
      </Row>
    </div>
  );
};

export default QuickLinks;
