import { observable, action, runInAction, makeObservable } from 'mobx';
import {
  getStations,
  insertStation,
  updateStation,
  publish,
  deleteStation,
  getStation,
} from '../api/station';
import { FORMS } from '../utils/enum';
import { validateStation } from '../utils/funcs';

export class StationStore {
  disablePublish = false;
  selectedStation = null;
  stations = [];
  areaClassifications = [];
  referenceSystem = [];
  municipalities = [];
  stationClassifications = [];
  networks = [];
  dispersionRegionals = [];
  dispersionLocals = [];
  trafficSpeeds = [];
  assessmentTypes = [];
  measurementTypes = [];
  equivalenceDemonstrated = [];
  emissionSources = [];
  relatedPartys = [];
  measurementMethods = [];
  analyticalTechniques = [];
  measurementEquipments = [];
  durationUnits = [];
  cadenceUnits = [];
  samplingEquipments = [];
  samplingMethods = [];
  airPollutants = [];
  percipitationPollutants = [];
  buildings = [];
  performers = [];
  primaryObservationTime = [];
  errorMessage = null;
  initialErrorMessage = null;
  stationFormErrorMessage = null;
  extendedFormErrorMessage = null;
  stationFormSuccessMessage = null;
  extendedFormSuccessMessage = null;
  successMessage = null;
  yesOrNo = [
    { key: true, value: 'Ja' },
    { key: false, value: 'Nej' },
  ];
  airOrPercipitation = [
    { key: 'Luft', value: 'Luft' },
    { key: 'Nederbörd', value: 'Nederbörd' },
  ];

  constructor(rootStore) {
    makeObservable(this, {
      initialErrorMessage: observable,
      setInitialErrorMessage: action,
      stationFormErrorMessage: observable,
      extendedFormErrorMessage: observable,
      selectedStation: observable,
      setSelectedStation: action,
      setStation: action,
      getStations: action,
      stations: observable,
      extendedFormSuccessMessage: observable,
      stationFormSuccessMessage: observable,
      successMessage: observable,
      errorMessage: observable,
      disablePublish: observable,
      setDisablePublish: action,
      setErrorMessage: action,
      setSuccessMessage: action,
      clearMessage: action,
      clearMessages: action,
      setStations: action,
    });

    this.rootStore = rootStore;
  }

  setDisablePublish = async (station) => {
    if (!station) return;
    const formIsNotSaved =
      this.selectedStation &&
      !this.selectedStation.extendedInformation.areaClassification;

    const validErrors = await validateStation(station, this.selectedStation);
    this.disablePublish =
      formIsNotSaved || !!(validErrors && validErrors !== '');
  };

  setStations = (stations) => {
    this.stations = stations;
  };

  setStation = (station) => {
    this.selectedStation = station;
  };

  setInitialErrorMessage = (initialErrorMessage) => {
    this.initialErrorMessage = initialErrorMessage;
  };

  setErrorMessage = (form, error) => {
    switch (form) {
      case FORMS.STATIONS:
        this.stationFormErrorMessage = error;
        break;
      case FORMS.EXTENDEDSTATIONS:
        this.extendedFormErrorMessage = error;
        break;
      default:
        this.errorMessage = error;
        break;
    }
  };

  setSuccessMessage = (form, success) => {
    switch (form) {
      case FORMS.STATIONS:
        this.stationFormSuccessMessage = success;
        break;
      case FORMS.EXTENDEDSTATIONS:
        this.extendedFormSuccessMessage = success;
        break;
      default:
        this.successMessage = success;
        break;
    }
  };

  clearMessage = (form) => {
    switch (form) {
      case FORMS.STATIONS:
        this.stationFormErrorMessage = null;
        this.stationFormSuccessMessage = null;
        break;
      case FORMS.EXTENDEDSTATIONS:
        this.extendedFormErrorMessage = null;
        this.extendedFormSuccessMessage = null;
        break;
      default:
        this.errorMessage = null;
        this.successMessage = null;
        break;
    }
  };

  clearMessages = () => {
    this.stationFormErrorMessage = null;
    this.extendedFormErrorMessage = null;
    this.stationFormSuccessMessage = null;
    this.extendedFormSuccessMessage = null;
    this.errorMessage = null;
    this.successMessage = null;
  };

  setSelectedStation = async (selectedStation, token, form) => {
    if (!selectedStation) return;
    this.clearMessages();
    const response = await getStation(selectedStation.natlStationCode, token);
    if (!selectedStation || selectedStation.error) {
      const errorMessage =
        response.error ||
        'Hämtningen av utökad stationsinformation misslyckades';
      this.setErrorMessage(FORMS.STATIONS, errorMessage);
    }
    const uniqueTemplate = response.measurements
      .map((item) => item.templateClassification)
      .filter((value, index, self) => self.indexOf(value) === index);

    const templates = {};
    uniqueTemplate.forEach((template) => {
      templates[template] = response.measurements.filter((measurement) => {
        return measurement.templateClassification === template;
      });
    });

    response.templates = templates;

    if (!form) {
      this.setStation(response);
    } else {
      switch (form) {
        case FORMS.MEASUREMENTS:
          Object.keys(response).forEach((property) => {
            if (
              typeof response[property] === 'object' &&
              response[property] !== null
            )
              return;
            this.selectedStation[property] = response[property];
          });
          break;
        case FORMS.EXTENDEDSTATIONS:
          this.selectedStation.extendedInformation =
            response.extendedInformation;
          break;
        default:
          break;
      }
    }
    await this.setDisablePublish(this.selectedStation);
  };

  async updateStation(data, form, token) {
    this.clearMessage(form);
    if (
      data.extendedInformation &&
      !isNaN(parseInt(data.extendedInformation.trafficSpeed))
    ) {
      data.extendedInformation.trafficSpeed = parseInt(
        data.extendedInformation.trafficSpeed
      );
    }
    const url =
      form === FORMS.EXTENDEDSTATIONS
        ? '/api/stationExtended'
        : `/api/station?network=${data.extendedInformation.network}`;
    const resp = await updateStation(data, url, token);
    if (resp && resp.error) {
      this.setErrorMessage(
        form,
        resp.error || 'Något gick fel när stationen skulle uppdateras'
      );
    } else {
      runInAction(async () => {
        this.setStations([]);
        this.setStation(data);
        await this.getStations(token);
        this.setSuccessMessage(form, 'Stationen uppdaterad');
        await this.setDisablePublish(this.selectedStation);
      });
    }
    return resp;
  }

  async insertStation(data, form, token) {
    this.clearMessage(form);
    const station = await insertStation(data, token);
    if (!station || station.error) {
      this.setErrorMessage(
        form,
        station.error || 'Något gick fel när stationen skulle skapas'
      );
    } else {
      runInAction(async () => {
        data.natlStationCode = station.natlStationCode;
        await this.setSelectedStation(data, token);
        await this.getStations(token);
        this.stationFormSuccessMessage = 'Stationen skapad';
        await this.setDisablePublish(this.selectedStation);
      });
    }
  }

  async publishStation(station, token, setError, setSuccess) {
    const resp = await publish(
      station.natlStationCode,
      !station.published,
      token
    );
    if (resp && resp.error) {
      setError
        ? setError('Något gick fel när stationen skulle publiceras')
        : this.setErrorMessage(
            '',
            'Något gick fel när stationen skulle publiceras'
          );
    } else {
      runInAction(async () => {
        await this.getStations(token);
        if (station.extendedInformation) {
          await this.setSelectedStation(station, token);
        }
        setSuccess
          ? setSuccess(
              station.published
                ? 'Stationen publicerades'
                : 'Stationen avpublicerades'
            )
          : this.setSuccessMessage(
              '',
              station.published
                ? 'Stationen avpublicerades'
                : 'Stationen publicerades'
            );
      });
    }
  }

  async deleteStation(setError, token) {
    const station = await deleteStation(
      this.selectedStation.natlStationCode,
      token
    );
    if (!station || station.error) {
      setError('Något gick fel när stationen skulle tas bort');
    } else {
      runInAction(async () => {
        await this.getStations(token);
        this.setStation(null);
      });
    }
  }

  getStations = async (token) => {
    const response = await getStations(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av stationer';
      this.setInitialErrorMessage(errorMessage);
    } else {
      this.setStations(response.stations);
      this.areaClassifications = response.areaClassifications;
      this.municipalities = response.municipalities;
      this.stationClassifications = response.stationClassifications;
      this.networks = response.networks;
      this.dispersionRegionals = response.dispersionRegional;
      this.dispersionLocals = response.dispersionLocal;
      this.trafficSpeeds = response.trafficSpeed;
      this.assessmentTypes = response.assessmentTypes;
      this.measurementTypes = response.measurementTypes;
      this.equivalenceDemonstrated = response.equivalenceDemonstrated;
      this.emissionSources = response.emissionSources;
      this.relatedPartys = response.relatedParty;
      this.measurementMethods = response.measurementMethod;
      this.analyticalTechniques = response.analyticalTechnique;
      this.measurementEquipments = response.measurementEquipment;
      this.durationUnits = response.durationUnit;
      this.cadenceUnits = response.cadenceUnit;
      this.samplingEquipments = response.samplingEquipment;
      this.samplingMethods = response.samplingMethod;
      this.airPollutants = response.airPollutant;
      this.percipitationPollutants = response.percipitationPollutant;
      this.buildings = response.buildings;
      this.performers = response.performers;
      this.trafficSpeeds = response.trafficSpeed;
      this.referenceSystem = response.referenceSystem;
      this.primaryObservationTime = response.primaryObservationTime;
    }
  };
}
