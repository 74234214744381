import Keycloak from 'keycloak-js';
const keycloakConfig = new Keycloak({
  // url: 'http://localhost:8180/auth',
  // realm: 'external',
  // clientId: 'nationalaqreporter-utv.smhi.se-frontend',
  url: 'https://auth.smhi.se/auth/',
  realm: 'external',
  clientId: 'nationalaqreporter-frontend.smhi.se',
});

export default keycloakConfig;
