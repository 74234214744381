import React, { useState, useEffect } from 'react';
import { ChevronUpOutline, ChevronDownOutline } from '@aurora/icons-react';
import PropTypes from 'prop-types';
import { H2, Subtitle1 } from '@aurora/widgets-react';
import './SideMenu.css';

const CollapsableItem = ({
  text,
  icon,
  divider = false,
  open = false,
  children,
  bold = false,
  percent,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggleOpen = () => {
    setIsOpen((open) => !open);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      toggleOpen();
    }
  };

  return (
    <div
      className={`side-menu--collapsable-item
        ${divider ? 'divider' : ''}`}
    >
      <div
        role="button"
        tabIndex="0"
        aria-label={text}
        aria-expanded={isOpen}
        className="collapsable-item--content"
        onClick={toggleOpen}
        onKeyDown={handleKeyDown}
      >
        {icon}
        {!percent && percent !== 0 ? (
          <div className="flex" aria-expanded={isOpen} role="list">
            <span className={`collapsable-item--text ${bold ? 'bold' : ''}`}>
              {`${text}`}
            </span>
            <span className="right-align">
              {isOpen ? <ChevronUpOutline /> : <ChevronDownOutline />}
            </span>
          </div>
        ) : (
          <div className="flex" aria-expanded={isOpen} role="list">
            <H2>{text}</H2>
            <Subtitle1 className="right-align">{`${percent}% obligatoriska uppgifter ifyllda`}</Subtitle1>
            {isOpen ? (
              <ChevronUpOutline size="m" />
            ) : (
              <ChevronDownOutline size="m" />
            )}
          </div>
        )}
      </div>
      {isOpen && <div className="collapsable-item--children">{children}</div>}
    </div>
  );
};

CollapsableItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  divider: PropTypes.bool,
  open: PropTypes.bool,
  children: PropTypes.any,
  bold: PropTypes.bool,
  percent: PropTypes.number,
};

export default CollapsableItem;
