import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  getNetworks,
  saveNetwork,
  deleteNetwork,
  saveDocument,
} from '../api/network';

export class NetworkStore {
  selectedNetwork = null;
  selectedDocument = null;
  networks = [];
  networkFormErrorMessage = null;
  networkFormSuccessMessage = null;
  documentFormErrorMessage = null;
  documentFormSuccessMessage = null;
  relatedPartys = [];
  networkTypes = [];

  constructor(rootStore) {
    makeObservable(this, {
      networkFormErrorMessage: observable,
      selectedNetwork: observable,
      selectedDocument: observable,
      setSelectedNetwork: action,
      setSelectedDocument: action,
      saveNetwork: action,
      getNetworks: action,
      networks: observable,
      networkFormSuccessMessage: observable,
      setErrorMessage: action,
      setSuccessMessage: action,
      setDocumentErrorMessage: action,
      setDocumentSuccessMessage: action,
      clearMessages: action,
      setNetworks: action,
    });

    this.rootStore = rootStore;
  }

  setErrorMessage = (error) => {
    this.networkFormErrorMessage = error;
  };

  setSuccessMessage = async (success) => {
    this.networkFormSuccessMessage = success;
  };

  setDocumentErrorMessage = (error) => {
    this.documentFormErrorMessage = error;
  };

  setDocumentSuccessMessage = async (success) => {
    this.documentFormSuccessMessage = success;
  };

  clearMessages = () => {
    this.networkFormErrorMessage = null;
    this.networkFormSuccessMessage = null;
    this.documentFormErrorMessage = null;
    this.documentFormSuccessMessage = null;
  };

  setNetworks = (networks) => {
    this.networks = networks;
  };

  setSelectedNetwork = (selectedNetwork) => {
    this.selectedNetwork = selectedNetwork;
  };

  setSelectedNetworkById = (id) => {
    if (!id) return;
    this.setSelectedNetwork(this.networks.find((network) => network.id === id));
  };

  setSelectedNetworkByProperties = (data) => {
    if (!data) return;
    this.setSelectedNetwork(
      this.networks.find(
        (network) =>
          network.network === data.network &&
          network.responsibleParty === data.responsibleParty &&
          network.type === data.type
      )
    );
  };

  setSelectedDocument = (selectedDocument) => {
    if (!selectedDocument) return;
    this.selectedDocument = selectedDocument;
  };

  async saveNetwork(data, token) {
    this.clearMessages();
    const response = await saveNetwork(data, token);
    if (response && response.error) {
      this.setErrorMessage(
        response.error ||
          `Något gick fel när nätverk skulle ${
            data.id ? 'uppdateras' : 'skapas'
          }`
      );
    } else {
      runInAction(async () => {
        this.setNetworks([]);
        await this.getNetworks(token);
        this.setSelectedNetworkByProperties(data);
        data.id
          ? this.setSuccessMessage('Nätverket uppdaterad')
          : this.setSuccessMessage('Nätverket skapat');
      });
    }
  }

  async saveDocument(data, token) {
    const networkId = this.selectedNetwork.id;
    this.clearMessages();
    const response = await saveDocument(data, networkId, token);
    if (response && response.error) {
      this.setDocumentErrorMessage(
        response.error || `Något gick fel när dokument skulle sparas`
      );
    } else {
      runInAction(async () => {
        this.setDocumentSuccessMessage('Dokument sparat');
        this.setNetworks([]);
        await this.getNetworks(token);
        //this.setSelectedNetworkById(networkId);
        this.setSelectedDocument(data);
      });
    }
  }

  async deleteNetwork(setError, token) {
    const network = await deleteNetwork(this.selectedNetwork.id, token);
    if (!network || network.error) {
      setError('Något gick fel när Nätverk och dokument skulle tas bort');
    } else {
      runInAction(async () => {
        this.setNetworks([]);
        await this.getNetworks(token);
        this.setSelectedNetwork(null);
      });
    }
  }

  getNetworks = async (token) => {
    const response = await getNetworks(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av nätverk och dokument';
      this.networkFormErrorMessage = errorMessage;
    } else {
      if (!response.networks) {
        return;
      }
      this.setNetworks(response.networks);
      this.relatedPartys = response.relatedParty;
      this.networkTypes = response.networkTypes;
    }
  };
}
