import React from 'react';
import PropTypes from 'prop-types';

const AdminText = ({ pageType }) => {
  return (
    <>
      {pageType === 'network' && (
        <span>
          Här kan du se vilket eller vilka nätverk som är kopplade till din
          inloggning. Du kan vid behov skapa nya nätverk och redigera uppgifter
          om ansvariga organisation och nätverkets typ. Alla mätstationer är
          kopplade till ett nätverk (se under Stationer och mätningar,
          Mätstation)..
          <br />
          <br />
          Du kan också rapportera in viktiga dokument som rör kontroll av
          luftkvalitet inom din kommun eller ditt samverkansområde. Klicka först
          på nätverket som dokumenten gäller för. Välj sedan vilket år
          dokumenten avser. Rapportera därefter kontrollstrategi,
          kvalitetssäkringsprogram, program för samordnad kontroll och/eller
          årsrapport genom att ange en webblänk till dokumenten.
          <br />
          <br /> Observera att en webblänk måste gå till en giltig URL till ett
          publicerat dokument.
          <br />
          <br />
        </span>
      )}
      {pageType === 'organisation' && (
        <span>
          Här kan du se och redigera uppgifter om din organisation.
          Organisationen kan vara en kommun, ett samverkansområde eller en
          konsult och används för att ange ansvar för mätnätverk, mätstationer
          och mätningar (inkl. ev utförare/konsult).
          <br />
          <br />
          Om en organisation saknas i listan, kan du lägga till den genom att
          klicka på <i>+ Ny organisation.</i>
          <br />
          <br />
        </span>
      )}
      {pageType === 'user' && (
        <span>
          Här kan du se vilken eller vilka användare som är kopplade till din
          organisation. Kontrollera att dina kontaktuppgifter stämmer.
          <br />
          <br /> För varje organisation finns en huvudansvarig användare.
          Kontakta
          <br />
          <a
            tabIndex="0"
            href="mailto:rapporteringluftkvalitet@naturvardsverket.se"
          >
            rapporteringluftkvalitet@naturvardsverket.se
          </a>{' '}
          och{' '}
          <a tabIndex="0" href="mailto:datavardluft@smhi.se">
            datavardluft@smhi.se
          </a>{' '}
          för att lägga till nya användare inom din organisation.
          <br />
          <br />
        </span>
      )}
    </>
  );
};

AdminText.propTypes = {
  pageType: PropTypes.string,
};

export default AdminText;
