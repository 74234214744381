import { UiStore } from './UiStore';
import { StationStore } from './StationStore';
import { MeasurementStore } from './MeasurementStore';
import { UserStore } from './UserStore';
import { OrganisationStore } from './OrganisationStore';
import { ReportStore } from './ReportStore';
import { AuthStore } from './AuthStore';
import { NetworkStore } from './NetworkStore';
import { RealtimeDataStore } from './RealtimeDataStore';
import { EureportStore } from './EureportStore';
// Combine stores
// https://mobx-react.js.org/recipes-context#multiple-global-stores
// https://mobx.js.org/best/store.html#combining-multiple-stores
export class RootStore {
  constructor() {
    this.uiStore = new UiStore(this);
    this.stationStore = new StationStore(this);
    this.measurementStore = new MeasurementStore(this);
    this.userStore = new UserStore(this);
    this.organisationStore = new OrganisationStore(this);
    this.reportStore = new ReportStore(this);
    this.authStore = new AuthStore(this);
    this.networkStore = new NetworkStore(this);
    this.realtimeDataStore = new RealtimeDataStore(this);
    this.eureportStore = new EureportStore(this);
  }
}
