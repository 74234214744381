//Prepeard for tab-handling. Just remove the comments and add menuitems.
import React from 'react';
import propTypes from 'prop-types';
import './RealtimeDataContainer.css';
import { H1, Body1 } from '@aurora/widgets-react';
import { observer } from 'mobx-react-lite';
import RealTimeText from '../../../RealTimeText';

const RealtimeDataContainer = observer(({ header, type, children }) => (
  <>
    <div id="realtimedata-container">
      <H1 tabIndex="0">{header}</H1>
      <Body1 tabIndex="0" className="text">
        <RealTimeText pageType={type} />
      </Body1>
      <hr />
      {children}
    </div>
  </>
));

RealtimeDataContainer.propTypes = {
  header: propTypes.node,
  type: propTypes.string,
  children: propTypes.node,
};

export default RealtimeDataContainer;
