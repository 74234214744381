import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  getEureport,
  getYears,
  getAssesmentregimes,
  updateAssesmentregimesSamplingPoint,
} from '../api/eureport';

export class EureportStore {
  eureport = [];
  cachedEureport = [];
  initialErrorMessage = null;
  successMessage = null;
  years = [];
  assesmentregimes = [];

  constructor(rootStore) {
    makeObservable(this, {
      initialErrorMessage: observable,
      getEureport: action,
      eureport: observable,
      cachedEureport: observable,
      setEureport: action,
      setCachedEureport: action,
      years: observable,
      getYears: action,
      setYears: action,
      getAssesmentregimes: action,
      assesmentregimes: observable,
      setAssesmentregimes: action,
      setSuccessMessage: action,
    });

    this.rootStore = rootStore;
  }

  setErrorMessage = (error) => {
    this.initialErrorMessage = error;
  };

  clearMessages = () => {
    this.initialErrorMessage = null;
  };

  setSuccessMessage = (success) => {
    this.successMessage = success;
  };

  getEureport = async (token, assementInspireId, year) => {
    const response = await getEureport(token, {
      assementinspireid: assementInspireId,
      year: year,
    });
    if (!response || response.error) {
      const errorMessage =
        response.error ||
        'Något gick fel vid hämtning av mätningar och assementregimes';
      this.setErrorMessage(errorMessage);
    } else {
      this.setEureport(response);
      this.setCachedEureport(response);
    }
  };

  setEureport = (eureport) => {
    this.eureport = eureport;
  };

  setCachedEureport = (eureport) => {
    this.cachedEureport = eureport;
  };

  getYears = async (token) => {
    const response = await getYears(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av eurapportens år';
      this.setErrorMessage(errorMessage);
    } else {
      this.setYears(response);
    }
  };

  setYears = (years) => {
    this.years = years;
  };

  getAssesmentregimes = async (token) => {
    const response = await getAssesmentregimes(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av assesmentregimes';
      this.setErrorMessage(errorMessage);
    } else {
      this.setAssesmentregimes(response);
    }
  };

  setAssesmentregimes = (assesmentregimes) => {
    this.assesmentregimes = assesmentregimes;
  };

  updateAssesmentregimesSamplingPoint = async (
    token,
    assesmentregimeSamplingpoints,
    year
  ) => {
    const response = await updateAssesmentregimesSamplingPoint(
      token,
      assesmentregimeSamplingpoints
    );
    if (response && response.error) {
      const errorMessage =
        response.error ||
        'Något gick fel vid uppdatering när assesmentregimes och samplingpoint kopplades ';
      this.setErrorMessage(errorMessage);
    } else {
      runInAction(async () => {
        if (assesmentregimeSamplingpoints[0]) {
          this.getEureport(
            token,
            assesmentregimeSamplingpoints[0].assesmentinspireid,
            year
          );
        }
      });
    }
  };
}
