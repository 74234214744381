/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './ReportStationList.css';
import Grid from '../../Grid';
import SearchBar from '../../SearchBar';
import { Select } from '@aurora/widgets-react';
import { useStores } from '../../../hooks/useStores';
import { ENTITY, SELECTED_CONTENT } from '../../../utils/enum';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import ButtonGroup from '../../ButtonGroup';
import { useKeycloak } from '@react-keycloak/web';
import Error from '../../Error';
import Loading from '../../Loading';
import Success from '../../Success';
import { DownloadOutline, HistoryOutline } from '@aurora/icons-react';
import { generateArrayOfYears } from '../../../utils/funcs';

const ReportStationList = observer(
  ({ searchTerm, setSearchTerm, hide = false }) => {
    const { keycloak } = useKeycloak();
    const currentPage = window.location.pathname;
    const [isStatus, setIsStatus] = useState(
      currentPage === '/rapportering/rapportera/oversikt'
    );
    const [loading, setLoading] = useState(false);
    const [years, setYears] = useState([]);
    const [observationsStatusSearchTerm, setObservationsStatusSearchTerm] =
      useState('');

    const [selectedContent, setSelectedContent] = useState(
      SELECTED_CONTENT.ALL
    );
    const [stationsObservations, setStationsObservations] = useState([]);
    const [reportStationsSearchResults, setReportStationSearchResults] =
      useState([]);
    const [
      observationsStatusSearchResults,
      setObservationsStatusSearchResults,
    ] = useState([]);
    const { reportStore, uiStore } = useStores();
    if (reportStore.selectedStationObservations) {
      uiStore.setObservationsStatusSearchResults(
        observationsStatusSearchResults
      );
    }

    useEffect(() => {
      setYears(generateArrayOfYears(new Date().getFullYear(), 1959));
    }, []);

    useEffect(() => {
      return () => {
        reportStore.clearMessages();
        reportStore.setStationsObservations(null);
        setReportStationSearchResults([]);
      };
    }, [reportStore]);

    useEffect(() => {
      currentPage === '/rapportering/rapportera/oversikt'
        ? setIsStatus(true)
        : setIsStatus(false);
    }, [currentPage]);

    useEffect(() => {
      if (!reportStore.selectedYear) return;
      const initData = async () => {
        reportStore.initialErrorMessage = null;
        try {
          setLoading(true);
          await reportStore.getStationsObservationsStatus(
            reportStore.selectedYear,
            keycloak.token,
            setStationsObservations,
            setLoading
          );
          if (reportStore.selectedStationObservations) {
            const selectedStationObservations =
              reportStore.stationsObservations.find(
                (x) =>
                  x.natlStationCode ===
                  reportStore.selectedStationObservations.natlStationCode
              );
            if (selectedStationObservations) {
              await reportStore.setSelectedStationObservations(
                selectedStationObservations,
                reportStore.selectedYear,
                keycloak.token,
                setLoading
              );
            }
          }
        } catch (e) {
          setLoading(false);
        }
      };
      if (
        !reportStore.stationsObservations &&
        reportStationsSearchResults.length === 0
      ) {
        initData();
      }
      const filterData = (filterValue) => {
        if (filterValue === 0) return reportStationsSearchResults;
        return reportStationsSearchResults.filter((stationObservation) => {
          if (filterValue === 4) {
            if (stationObservation.isMissingStatus) {
              return stationObservation.isMissingStatus === true;
            }
          } else if (filterValue === 3 && stationObservation.maxStatus === 4) {
            return stationObservation.isMissingStatus === false;
          } else if (
            filterValue === 1 &&
            (stationObservation.maxStatus === 1 ||
              stationObservation.maxStatus === 5 ||
              stationObservation.maxStatus === 6)
          ) {
            return stationObservation.isMissingStatus === false;
          } else {
            return stationObservation.maxStatus === filterValue;
          }
        });
      };
      if (
        reportStationsSearchResults &&
        reportStationsSearchResults.length > 0
      ) {
        const stationsObservationsResult = filterData(selectedContent.value);
        setStationsObservations(stationsObservationsResult);
      }
    }, [
      keycloak,
      reportStationsSearchResults,
      reportStore,
      selectedContent.value,
    ]);

    const reactTableHeader = [
      {
        Header: 'Namn på mätstation',
        accessor: 'name',
      },
      {
        Header: 'Stationskod',
        accessor: 'natlStationCode',
      },
      {
        Header: 'Mätningar',
        accessor: 'pollutants',
      },
      {
        Header: 'Status',
        accessor: 'maxStatus',
        Cell: ({ row }) => {
          const data = row.original;
          return data.maxStatus === 1 ||
            data.maxStatus === 5 ||
            data.maxStatus === 6 ? (
            <p>Klar</p>
          ) : data.maxStatus === 2 ? (
            <p>Granskas</p>
          ) : data.maxStatus === 3 ||
            (data.maxStatus === 4 && !data.isMissingStatus) ? (
            <p>Ej klar</p>
          ) : (
            <p>Saknas</p>
          );
        },
      },
    ];

    if (isStatus) {
      reactTableHeader.push({
        Header: 'Mall',
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <span style={{ cursor: 'pointer' }}>
              <DownloadOutline
                onClick={async () => {
                  uiStore.setTriggeredFromDownload(true);
                  setLoading(true);
                  const distinctTemplates = data.templates
                    .split(', ')
                    .filter((x, i, a) => a.indexOf(x) == i);
                  await distinctTemplates.reduce(async (memo, template) => {
                    await memo;
                    await reportStore.downloadTemplate(
                      reportStore.selectedYear,
                      data.natlStationCode,
                      data.name,
                      template,
                      keycloak.token,
                      true
                    );
                    await reportStore.downloadTemplate(
                      reportStore.selectedYear,
                      data.natlStationCode,
                      data.name,
                      template,
                      keycloak.token,
                      false
                    );
                  }, undefined);

                  setLoading(false);
                  uiStore.setTriggeredFromDownload(false);
                }}
              />
            </span>
          );
        },
      });
    }
    if (isStatus) {
      reactTableHeader.push({
        Header: 'Arkiv',
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <span style={{ cursor: 'pointer' }}>
              <HistoryOutline
                onClick={async () => {
                  setLoading(true);
                  await reportStore.downloadArchiveFiles(
                    reportStore.selectedYear,
                    data.natlStationCode,
                    data.adminUnitId,
                    data.name,
                    keycloak.token
                  );
                  setLoading(false);
                }}
              />
            </span>
          );
        },
      });
    }

    const setStationObservations = (data) => {
      if (uiStore.triggeredFromDownload) return;
      reportStore.setErrorMessage(null);
      if (data) {
        reportStore.setSelectedStationObservations(
          data,
          reportStore.selectedYear,
          keycloak.token,
          setLoading
        );
        setSearchTerm(data.name);
        uiStore.setSelectedContent(SELECTED_CONTENT.ALL);
        setSelectedContent(SELECTED_CONTENT.ALL);
        const beginDate = new Date(data.beginTime);
        const beginYear = beginDate.getFullYear();
        let endYear = new Date().getFullYear();
        if (data.endTime != '') {
          endYear = new Date(data.endTime).getFullYear();
        }
        setYears(generateArrayOfYears(endYear, beginYear));
      } else {
        reportStore.setSelectedStationObservations(null);
        setSearchTerm('');
      }
    };

    return (
      <div id="report-station-list">
        <div className="top flex">
          <div className="station-search-width">
            <SearchBar
              setSelectedContent={setSelectedContent}
              setSearchResults={setReportStationSearchResults}
              searchEntity={ENTITY.STATIONS_OBSERVATIONS}
              header="Filtrera mätstation"
              placeholder="station, kod"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setEntity={setStationsObservations}
              label="Filtrera mätstation"
              setYears={setYears}
            />
          </div>
          {reportStore.selectedStationObservations &&
            reportStore.stationsObservations &&
            reportStore.stationsObservations.length > 0 && (
              <div className="measurement-search-width">
                <SearchBar
                  setSearchResults={setObservationsStatusSearchResults}
                  searchEntity={ENTITY.OBSERVATIONS_STATUS}
                  header="Filtrera observation"
                  placeholder="Förorening, obsid"
                  searchTerm={observationsStatusSearchTerm}
                  setSearchTerm={setObservationsStatusSearchTerm}
                  label="Filtrera mätning"
                />
              </div>
            )}
          <div className="flex-right right-align">
            <ButtonGroup
              selectedContent={selectedContent}
              setSelectedContent={setSelectedContent}
              // disabled={reportStore.selectedStationObservations}
              buttons={[
                SELECTED_CONTENT.ALL,
                SELECTED_CONTENT.FINISHED,
                SELECTED_CONTENT.EXAMINED,
                SELECTED_CONTENT.NOTFINISHED,
                SELECTED_CONTENT.MISSING,
              ]}
            />
            <Select
              className="right-align"
              multiple={false}
              native
              list={years}
              onChange={(e) => {
                reportStore.setSelectedYear(e.target.value);
                setStationsObservations([]);
                setReportStationSearchResults([]);
                reportStore.setSelectedMetaData(null);
              }}
              value={reportStore.selectedYear}
              label="Avser år"
            />
          </div>
        </div>
        <Loading isLoading={loading} text="Loading data..." />
        {reportStore.formErrorMessage && (
          <Error error={reportStore.formErrorMessage} />
        )}
        {reportStore.downloadErrorMessage && (
          <Error error={reportStore.downloadErrorMessage} />
        )}
        {reportStore.downloadSuccessMessage && (
          <Success success={reportStore.downloadSuccessMessage} />
        )}
        {reportStore.downloadArchiveErrorMessage && (
          <Error error={reportStore.downloadArchiveErrorMessage} />
        )}
        {reportStore.downloadArchiveSuccessMessage && (
          <Success success={reportStore.downloadArchiveSuccessMessage} />
        )}
        {!reportStore.formErrorMessage && !loading && !hide && (
          <>
            <Grid
              reactTableHeader={reactTableHeader}
              gridData={stationsObservations}
              onRowClick={setStationObservations}
              gridEntity={ENTITY.STATIONS_OBSERVATIONS}
            />
          </>
        )}
      </div>
    );
  }
);

ReportStationList.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default ReportStationList;
