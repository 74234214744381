import { bearerAuth } from '../utils/funcs';

export async function getNetworks(token) {
  try {
    const response = await fetch(`/api/network`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av nätverk och dokument misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av nätverk och dokument misslyckades' };
  }
}

export async function saveNetwork(network, token) {
  try {
    const response = await fetch(`/api/network`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(network),
    });
    if (!response.ok) {
      return {
        error: `Det gick inte att ${
          network.id ? 'uppdatera' : 'skapa'
        } aktuellt nätverk`,
      };
    }
  } catch (e) {
    return {
      error: `Det gick inte att ${
        network.id ? 'uppdatera' : 'skapa'
      } aktuellt nätverk`,
    };
  }
}

export async function saveDocument(document, networkId, token) {
  try {
    const response = await fetch(`/api/network/${networkId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(document),
    });
    if (!response.ok) {
      return {
        error: `Det gick inte att spara aktuellt dokument`,
      };
    }
  } catch (e) {
    return {
      error: `Det gick inte att spara aktuellt dokument`,
    };
  }
}

export async function deleteNetwork(id, token) {
  try {
    const response = await fetch(`/api/network/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att ta bort aktuell nätverk och dokument',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att ta bort aktuell nätverk och dokument' };
  }
}
