export const FORMS = {
  STATIONS: 'stations',
  USERS: 'users',
  EXTENDEDSTATIONS: 'extended',
  EQUIPMENT: 'equipment',
  INLET: 'inlet',
  EMISSIONSOURCES: 'emissionSources',
  MEASUREMENTS: 'measurements',
  ORGANISATIONS: 'organisations',
  META: 'meta',
  REPORT: 'report',
  NETWORK: 'network',
  DOCUMENT: 'document',
};

export const ENTITY = {
  STATIONS: 'stations',
  MEASUREMENTS: 'measurements',
  USERS: 'users',
  ORGANISATIONS: 'organisations',
  META: 'meta',
  REPORT: 'report',
  RECEIPT: 'receipt',
  STATIONS_OBSERVATIONS: 'stationsObservations',
  OBSERVATIONS_STATUS: 'observationsStatus',
  NETWORKS: 'networks',
  REALTIMEDATA: 'realtimeData',
  AUDIT: 'audit',
  AUDIT_CALCULATOR: 'auditCalculator',
  EU_REPORT: 'euReport',
};

export const SELECTEDTYPES = {
  TRAFFIC: 'Gaturum',
  INDUSTRI: 'Industrimiljö',
  ACTIVESAMPLING: 'Aktiv insamling',
  PASSIVEAMPLING: 'Passiv insamling',
  AUTOMATICANALYZER: 'Automatiskt mätinstrument',
  REMOTESENSOR: 'Remote sensor',
  URBAN: 'Tätort',
  SUBURBAN: 'Förort',
  DOAS: 'DOAS',
  SWEREF99: 'SWEREF99',
};

export const SELECTED_CONTENT = {
  ALL: { value: 0, title: 'Alla' },
  FINISHED: { value: 1, title: 'Klar' },
  EXAMINED: { value: 2, title: 'Granskas' },
  NOTFINISHED: { value: 3, title: 'Ej klar' },
  MISSING: { value: 4, title: 'Saknas' },
  INACTIVE: { value: 5, title: 'Inaktiva' },
  ACTIVE: { value: 6, title: 'Aktiva' },
};

export const REPORT_STEP = {
  ONE: { step: 'one', title: 'Validera' },
  TWO: { step: 'two', title: 'Ladda upp' },
  THREE: { step: 'three', title: 'Rapportera fler mätningar' },
};

export const REPORT_STATUS = {
  1: 'Klar',
  2: 'Granskas',
  3: 'Ej klar',
  4: 'Saknas',
  5: 'Ej obligatorisk (klar)',
  6: 'Inga data (klar)',
};

export const REPORT_VALID_RESULT = {
  VALID: 'Godkänd',
  NOT_VALID: 'Fel',
  WARNING: 'Varning',
  CONFIRMED: 'Bekräftad',
};

export const REPORT_VALID_STATUS = {
  Godkänd: { color: '#3DA000' },
  Varning: { color: '#C9B200' },
  'Varning. Ej rapporterad': { color: '#C9B200' },
  Bekräftad: { color: '#3B9CDF' },
  Fel: { color: '#F82B37' },
  'Fel. Ej rapporterad': { color: '#F82B37' },
};

export const USER_ROLE = {
  GROUPUSER: 'GROUPUSER',
  GROUPADMIN: 'GROUPADMIN',
  SUPERUSER: 'SUPERUSER',
  SYSADMIN: 'SYSADMIN',
  NATIONALSUPERVISOR: 'NATIONALSUPERVISOR',
};
