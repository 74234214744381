import React, { useState } from 'react';
import { Dark, Button, Body1 } from '@aurora/widgets-react';
import { CloseOutline } from '@aurora/icons-react';
import './AppRoot.css';

const CookieNotice = () => {
  const storedValue = localStorage.getItem('hideCookieNotice');
  const [hide, setHide] = useState(
    storedValue !== null ? JSON.parse(storedValue) : false
  );

  const closeCookieNotice = () => {
    setHide(true);
    localStorage.setItem('hideCookieNotice', JSON.stringify(true));
  };

  if (hide) {
    return null;
  }

  return (
    <div className="cookies-notice">
      <Dark className="cookies-notice--content">
        <Body1 className="cookies-notice--text">
          Den här webbplatsen använder nödvändiga cookies för att vår webbplats
          ska fungera. Dessa cookies samlas in anonymt. För mer detaljerad
          information om de cookies vi använder, se vår sida om&nbsp;
          <a
            href="https://www.smhi.se/omsmhi/hantering-av-personuppgifter/hantering-av-personuppgifter-1.135429"
            target="_blank"
            rel="noopener noreferrer"
            alt="SMHIs hantering av personuppgifter"
          >
            <b>cookies</b>
          </a>
          .
        </Body1>
        <Button
          icon={<CloseOutline size="m" />}
          label="Stäng"
          shape={Button.shapes.round}
          variant={Button.variants.quaternary}
          onClick={closeCookieNotice}
        />
      </Dark>
    </div>
  );
};

export default CookieNotice;
