import React from 'react';
import PropTypes from 'prop-types';

const AuditText = ({ pageType }) => {
  return (
    <>
      {pageType === 'audit' && (
        <span>
          Här kan du se förändringar i databasens tabeller, vem som gjort dem
          och när det är ufört.
          <br />
          <br />
        </span>
      )}
      {pageType === 'auditCalculator' && (
        <span>
          För att minska belastningen på våra servrar så räknar vi ut statistik
          för mätningar en i taget allt eftersom de rapporteras in. Här kan du
          se nyligen inrapporterade mätningar som ligger i kö för att bli
          behandlade.
          <br />
          <br />
          Beräknad statstik syns sen i databastabellen <i>statresult</i>.
        </span>
      )}
    </>
  );
};

AuditText.propTypes = {
  pageType: PropTypes.string,
};

export default AuditText;
