import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  getUsers,
  saveUser,
  deleteUser,
  getUsersByOrganisationName,
} from '../api/user';
import { FORMS } from '../utils/enum';

export class UserStore {
  selectedUser = null;
  users = [];
  subOrganisations = [];
  initialErrorMessage = null;
  userFormErrorMessage = null;
  userFormSuccessMessage = null;
  reportFormErrorMessage = null;

  constructor(rootStore) {
    makeObservable(this, {
      initialErrorMessage: observable,
      userFormErrorMessage: observable,
      userFormSuccessMessage: observable,
      selectedUser: observable,
      setSelectedUser: action,
      getUsers: action,
      users: observable,
      setErrorMessage: action,
      setSuccessMessage: action,
      clearMessage: action,
      clearMessages: action,
      setUsers: action,
    });

    this.rootStore = rootStore;
  }

  setErrorMessage = (form, error) => {
    switch (form) {
      case FORMS.USERS:
        this.userFormErrorMessage = error;
        break;
      case FORMS.REPORT:
        this.reportFormErrorMessage = error;
        break;
      default:
        break;
    }
  };

  setSuccessMessage = (form, success) => {
    switch (form) {
      case FORMS.USERS:
        this.userFormSuccessMessage = success;
        break;
      default:
        break;
    }
  };

  clearMessage = (form) => {
    switch (form) {
      case FORMS.USERS:
        this.userFormErrorMessage = null;
        this.userFormSuccessMessage = null;
        this.reportFormErrorMessage = null;
        break;
      default:
        break;
    }
  };

  clearMessages = () => {
    this.userFormErrorMessage = null;
    this.userFormSuccessMessage = null;
  };

  setSelectedUser = (selectedUser) => {
    this.selectedUser = selectedUser;
  };

  setUsers = (users) => {
    this.users = users;
  };

  async saveUser(data, token, organisation, loggedInUserId, isChangingRole) {
    this.clearMessages();
    const response = await saveUser(data, token, isChangingRole);
    if (response && response.error) {
      this.setErrorMessage(
        FORMS.USERS,
        response.error ||
          `Något gick fel när användaren skulle ${
            data.id ? 'uppdateras' : 'skapas'
          }`
      );
    } else {
      runInAction(async () => {
        this.setUsers([]);
        if (organisation) {
          await getUsersByOrganisationName(token, {
            organisationname: organisation,
          });
        } else {
          await this.getUsers(token, loggedInUserId);
        }
        const selectedUser = this.users.find((u) => {
          return (
            u.firstName === data.firstName &&
            u.lastName === data.lastName &&
            u.telephone === data.telephone &&
            u.email === data.email &&
            u.organisationName === data.organisationName
          );
        });
        this.setSelectedUser(selectedUser);

        data.id
          ? this.setSuccessMessage(FORMS.USERS, 'Användaren uppdaterad')
          : this.setSuccessMessage(FORMS.USERS, 'Användaren skapad');
      });
    }
  }
  async getUsersByOrganisationName(token, organisationName, setError) {
    const response = await getUsersByOrganisationName(token, {
      organisationname: organisationName,
    });
    if ((!response || response.error) && setError) {
      setError(
        response.error ||
          'Något gick fel vid hämtning av organsisationens användare'
      );
    } else {
      this.setUsers(response);
    }
  }

  async deleteUser(setError, token) {
    const user = await deleteUser(this.selectedUser.id, token);
    if (!user || user.error) {
      setError('Något gick fel när användaren skulle tas bort');
    } else {
      runInAction(async () => {
        await this.getUsers(token);
        this.setSelectedUser(null);
      });
    }
  }

  getUsers = async (token, loggedInId) => {
    const response = await getUsers(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av användare';
      this.initialErrorMessage = errorMessage;
    } else {
      this.setUsers(response);
      this.setSelectedUser(
        this.users.find((u) => {
          return u.id === loggedInId;
        })
      );
    }
  };

  getOrganisationUsers = async (organisation) => {
    return this.users.filter((user) => {
      user.organisationName === organisation;
    });
  };

  addUserSubOrganisations = (subOrganisationId, organisations) => {
    const addedOrganisation = organisations.find((organisation) => {
      return organisation.id === subOrganisationId;
    });
    this.selectedUser.subOrganisations.push(addedOrganisation);
  };

  removeUserSubOrganisations = (subOrganisationId) => {
    const indexArray = this.selectedUser.subOrganisations.map(
      (organisation) => {
        return organisation.id;
      }
    );
    const index = indexArray.indexOf(subOrganisationId);
    if (index > -1) {
      this.selectedUser.subOrganisations.splice(index, 1);
    }
  };
}
