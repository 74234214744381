import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  insertMeasurement,
  updateMeasurement,
  publish,
  deleteMeasurement,
} from '../api/measurement';
import { FORMS } from '../utils/enum';
import { validateMeasurement } from '../utils/funcs';

export class MeasurementStore {
  disablePublish = false;
  scrollMeasurementElement = null;
  selectedMeasurement = null;
  errorMessage = null;
  successMessage = null;
  measurementFormErrorMessage = null;
  measurementFormSuccessMessage = null;
  emissionSourcesFormErrorMessage = null;
  emissionSourcesFormSuccessMessage = null;
  equipmentFormErrorMessage = null;
  equipmentFormSuccessMessage = null;
  inletFormErrorMessage = null;
  inletFormSuccessMessage = null;

  constructor(rootStore) {
    makeObservable(this, {
      selectedMeasurement: observable,
      setSelectedMeasurement: action,
      measurementFormErrorMessage: observable,
      emissionSourcesFormErrorMessage: observable,
      inletFormErrorMessage: observable,
      inletFormSuccessMessage: observable,
      equipmentFormSuccessMessage: observable,
      emissionSourcesFormSuccessMessage: observable,
      measurementFormSuccessMessage: observable,
      errorMessage: observable,
      successMessage: observable,
      clearMessages: action,
      disablePublish: observable,
      setDisablePublish: action,
      setErrorMessage: action,
      setSuccessMessage: action,
      clearMessage: action,
    });

    this.rootStore = rootStore;
  }

  setDisablePublish = async (
    measurement,
    selectedStation,
    samplingEquipments,
    measurementEquipments,
    samplingMethods,
    measurementMethods
  ) => {
    if (!measurement) return;
    const formIsNotSaved =
      this.selectedMeasurement &&
      (!this.selectedMeasurement.observationId ||
        this.selectedMeasurement.observationId === '');

    const validErrors = await validateMeasurement(
      measurement,
      selectedStation,
      samplingEquipments,
      measurementEquipments,
      samplingMethods,
      measurementMethods
    );
    runInAction(() => {
      this.disablePublish =
        formIsNotSaved || !!(validErrors && validErrors !== '');
    });
  };

  setErrorMessage = (form, error) => {
    switch (form) {
      case FORMS.MEASUREMENTS:
        this.measurementFormErrorMessage = error;
        break;
      case FORMS.INLET:
        this.inletFormErrorMessage = error;
        break;
      case FORMS.EMISSIONSOURCES:
        this.emissionSourcesFormErrorMessage = error;
        break;
      case FORMS.EQUIPMENT:
        this.equipmentFormErrorMessage = error;
        break;
      default:
        this.errorMessage = error;
        break;
    }
  };

  setSuccessMessage = (form, success) => {
    switch (form) {
      case FORMS.MEASUREMENTS:
        this.measurementFormSuccessMessage = success;
        break;
      case FORMS.INLET:
        this.inletFormSuccessMessage = success;
        break;
      case FORMS.EMISSIONSOURCES:
        this.emissionSourcesFormSuccessMessage = success;
        break;
      case FORMS.EQUIPMENT:
        this.equipmentFormSuccessMessage = success;
        break;
      default:
        this.successMessage = success;
        break;
    }
  };

  clearMessage = (form) => {
    switch (form) {
      case FORMS.MEASUREMENTS:
        this.measurementFormErrorMessage = null;
        this.measurementFormSuccessMessage = null;
        break;
      case FORMS.INLET:
        this.inletFormErrorMessage = null;
        this.inletFormSuccessMessage = null;
        break;
      case FORMS.EMISSIONSOURCES:
        this.emissionSourcesFormErrorMessage = null;
        this.emissionSourcesFormSuccessMessage = null;
        break;
      case FORMS.EQUIPMENT:
        this.equipmentFormErrorMessage = null;
        this.equipmentFormSuccessMessage = null;
        break;
      default:
        this.errorMessage = null;
        this.successMessage = null;
        break;
    }
  };

  clearMessages = () => {
    this.inletFormSuccessMessage = null;
    this.equipmentFormSuccessMessage = null;
    this.emissionSourcesFormSuccessMessage = null;
    this.measurementFormSuccessMessage = null;
    this.measurementFormErrorMessage = null;
    this.emissionSourcesFormErrorMessage = null;
    this.equipmentFormErrorMessage = null;
    this.inletFormErrorMessage = null;
    this.errorMessage = null;
    this.successMessage = null;
  };

  async updateMeasurement(
    data,
    form,
    token,
    selectedStation,
    samplingEquipments,
    measurementEquipments,
    samplingMethods,
    measurementMethods
  ) {
    this.clearMessage(form);
    let url = '';
    switch (form) {
      case FORMS.MEASUREMENTS:
        url = '/api/measurement';
        break;
      case FORMS.INLET:
        url = '/api/measurement?update=inlet';
        break;
      case FORMS.EMISSIONSOURCES:
        url = '/api/measurement?update=emissionsources';
        break;
      case FORMS.EQUIPMENT:
        url = '/api/measurement?update=measurementprocess';
        break;
      default:
        break;
    }
    const measurement = await updateMeasurement(data, url, token);
    if (measurement && measurement.error) {
      this.setErrorMessage(
        form,
        measurement.error || 'Något gick fel när mätningen skulle uppdateras'
      );
    } else {
      runInAction(async () => {
        this.setSuccessMessage(form, 'Mätningen uppdaterad');
        switch (form) {
          case FORMS.MEASUREMENTS:
            Object.keys(data).forEach((property) => {
              if (typeof data[property] === 'object' && data[property] !== null)
                return;
              this.selectedMeasurement[property] = data[property];
            });
            break;
          case FORMS.INLET:
            this.selectedMeasurement.inlet = data.inlet;
            break;
          case FORMS.EMISSIONSOURCES:
            this.selectedMeasurement.emissionSources = data.emissionSources;
            break;
          case FORMS.EQUIPMENT:
            this.selectedMeasurement.measurementProcess =
              data.measurementProcess;
            break;
          default:
            break;
        }
        await this.setDisablePublish(
          this.selectedMeasurement,
          selectedStation,
          samplingEquipments,
          measurementEquipments,
          samplingMethods,
          measurementMethods
        );
      });
    }
  }

  async insertMeasurement(data, form, observationId, token) {
    this.clearMessage(form);
    const measurement = await insertMeasurement(data, observationId, token);
    if (!measurement || measurement.error) {
      this.setErrorMessage(
        form,
        measurement.error || 'Något gick fel när mätningen skulle skapas'
      );
    } else {
      this.setSelectedMeasurement(measurement);
      this.measurementFormSuccessMessage = 'Mätningen skapad';
    }
  }

  async publish(measurement, token, setError, setSuccess) {
    const resp = await publish(
      measurement.observationId,
      !measurement.published,
      token
    );
    if (resp && resp.error) {
      setError
        ? setError('Något gick fel när mätningen skulle publiceras')
        : this.setErrorMessage(
            '',
            'Något gick fel när mätningen skulle publiceras'
          );
    } else {
      runInAction(async () => {
        setSuccess
          ? setSuccess(
              measurement.published
                ? 'Mätningen avpublicerades'
                : 'Mätningen publicerades'
            )
          : this.setSuccessMessage(
              '',
              measurement.published
                ? 'Mätningen avpublicerades'
                : 'Mätningen publicerades'
            );
        measurement.published = !measurement.published;
      });
    }
  }

  async deleteMeasurement(setError, token) {
    const measurement = await deleteMeasurement(
      this.selectedMeasurement.observationId,
      token
    );
    if (!measurement || measurement.error) {
      setError(
        measurement.error || 'Något gick fel när mätningen skulle tas bort'
      );
    } else {
      runInAction(async () => {
        await this.getStations(token);
        await this.setSelectedStation(this.emptyStation);
      });
    }
  }

  setSelectedMeasurement = (measurement) => {
    this.selectedMeasurement = measurement;
  };

  setScrollMeasurementElement = (scrollMeasurementElement) => {
    if (!scrollMeasurementElement) return;
    this.scrollMeasurementElement = scrollMeasurementElement;
  };
}
