//Prepeard for tab-handling. Just remove the comments and add menuitems.
import React from 'react';
import propTypes from 'prop-types';
import './EureportContainer.css';
import { H1, Body1 } from '@aurora/widgets-react';
import { observer } from 'mobx-react-lite';
import EureportText from '../../../EureportText';
import { useStores } from '../../../hooks/useStores';
import { USER_ROLE } from '../../../utils/enum';
const EureportContainer = observer(({ header, type, children }) => {
  const { authStore } = useStores();
  return (
    <>
      {authStore.loggedInUser &&
        (authStore.loggedInUser.role === USER_ROLE.SUPERUSER ||
          authStore.loggedInUser.role === USER_ROLE.SYSADMIN) && (
          <div id="eureport-container">
            <H1 tabIndex="0">{header}</H1>
            <Body1 tabIndex="0" className="text">
              <EureportText pageType={type} />
            </Body1>
            <hr />
            {children}
          </div>
        )}
    </>
  );
});

EureportContainer.propTypes = {
  header: propTypes.node,
  type: propTypes.string,
  children: propTypes.node,
};

export default EureportContainer;
