import { bearerAuth } from '../utils/funcs';

export async function getRealtimeData(token) {
  try {
    const response = await fetch(`/api/realtimedata`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av realtidsdata misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av realtidsdata misslyckades' };
  }
}
