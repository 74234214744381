import React from 'react';
import PropTypes from 'prop-types';

const assessmentregimestext = ({ pageType }) => {
  return (
    <>
      {pageType === 'eureport' && (
        <span>
          Här kan du göra kopplingar mellan databastabellerna{' '}
          <i>assessmentregimes</i> och <i>samplingpoints</i>.
          <br />
          <br />
        </span>
      )}
    </>
  );
};

assessmentregimestext.propTypes = {
  pageType: PropTypes.string,
};

export default assessmentregimestext;
