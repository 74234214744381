import { makeObservable, observable, action } from 'mobx';
import { getUser } from '../api/auth';

export class AuthStore {
  loggedInUser = null;
  initialErrorMessage = null;
  unauthorizedMessage = null;

  constructor(rootStore) {
    makeObservable(this, {
      initialErrorMessage: observable,
      loggedInUser: observable,
      unauthorizedMessage: observable,
      getUser: action,
      setLoggedInUser: action,
    });

    this.rootStore = rootStore;
  }

  setLoggedInUser = (loggedInUser) => {
    this.loggedInUser = loggedInUser;
  };

  getUser = async (token) => {
    const response = await getUser(token);
    if (!response || response.error) {
      const errorMessage = response.error || 'Något gick fel vid inloggningen';
      this.initialErrorMessage = errorMessage;
    } else if (response && response.unauthorized) {
      this.unauthorizedMessage =
        response.unauthorized || 'Du är inte behörig för applikationen';
    } else {
      this.setLoggedInUser(response);
    }
  };
}
