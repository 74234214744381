import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import './StationContainer.css';
import { H1, Paragraph } from '@aurora/widgets-react';
import QuickLinks from '../../QuickLinks';
import LinkMenu from '../../LinkMenu';
import MenuItem from '../../SideMenu/MenuItem';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import StationList from '../StationList';
import { useKeycloak } from '@react-keycloak/web';
import WelcomeText from '../../../WelcomeText';

const StationsContainer = observer(({ header, type, children }) => {
  const { keycloak } = useKeycloak();
  const [searchTerm, setSearchTerm] = useState('');
  const { stationStore, uiStore } = useStores();
  const isStartPage = header === 'Rapportering av luftkvalitetsdata';
  const currentPage = window.location.pathname;
  const stationSelected =
    currentPage === '/rapportering/station' ||
    currentPage === '/rapportering/matningar' ||
    currentPage === '/rapportering/status';

  const showList =
    stationSelected &&
    (currentPage === '/rapportering/station' ||
      currentPage === '/rapportering/matningar');

  const hideTable =
    currentPage === '/rapportering/matningar' && stationStore.selectedStation;

  useEffect(() => {
    if (isStartPage) {
      setSearchTerm('');
    }
  }, [isStartPage]);

  return (
    <>
      <div id="station-container">
        {isStartPage && (
          <>
            <div className="common">
              <div className="left-side">
                <H1 tabIndex="0">{header}</H1>
                <Paragraph tabIndex="0" className="text">
                  <WelcomeText pageType={type} />
                </Paragraph>
              </div>
              <QuickLinks />
            </div>
          </>
        )}
        {!isStartPage && (
          <>
            <H1 tabIndex="0">{header}</H1>
            <Paragraph tabIndex="0" className="text">
              <WelcomeText pageType={type} />
            </Paragraph>
            <LinkMenu maxwidth={42}>
              <MenuItem
                text="Mätstation"
                onClick={() => {
                  uiStore.navigate('/rapportering/station');
                }}
                isStationMenu
                selected={currentPage === '/rapportering/station'}
              />
              <MenuItem
                text="Mätning"
                onClick={() => {
                  uiStore.navigate('/rapportering/matningar');
                }}
                isStationMenu
                selected={
                  stationSelected && currentPage === '/rapportering/matningar'
                }
              />
              <MenuItem
                text="Status"
                onClick={async () => {
                  uiStore.navigate('/rapportering/status');
                  await stationStore.getStations(keycloak.token);
                }}
                isStationMenu
                selected={
                  stationSelected && currentPage === '/rapportering/status'
                }
              />
            </LinkMenu>
            <hr />
            {showList && (
              <StationList
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                hide={hideTable}
              />
            )}
          </>
        )}
        {children}
      </div>
    </>
  );
});

StationsContainer.propTypes = {
  doSomething: propTypes.func,
  header: propTypes.node,
  type: propTypes.string,
  children: propTypes.node,
};

export default StationsContainer;
