import React from 'react';
import propTypes from 'prop-types';
import './LinkMenu.css';

const LinkMenu = ({ maxwidth, children }) => {
  return (
    <>
      <div
        role="tablist"
        id="link-menu"
        className="side-menu"
        style={{ maxWidth: `${maxwidth}rem` }}
      >
        {children}
      </div>
      <span>
        <hr />
      </span>
    </>
  );
};

LinkMenu.propTypes = {
  maxwidth: propTypes.number,
  children: propTypes.node,
};

export default LinkMenu;
