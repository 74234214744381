import React, { useEffect, useState, useRef } from 'react';
import './StationList.css';
import Grid from '../../Grid';
import SearchBar from '../../SearchBar';
import { Button } from '@aurora/widgets-react';
import { AddOutline } from '@aurora/icons-react';
import { useStores } from '../../../hooks/useStores';
import {
  emptyStation,
  emptyMeasurement,
  executeScrollToElement,
  isInactive,
} from '../../../utils/funcs';
import { ENTITY, FORMS, SELECTED_CONTENT } from '../../../utils/enum';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';
import ButtonGroup from '../../ButtonGroup';

const StationList = observer(({ searchTerm, setSearchTerm, hide = false }) => {
  const { keycloak } = useKeycloak();
  const [selectedContent, setSelectedContent] = useState(SELECTED_CONTENT.ALL);
  const scrollElement = useRef(null);
  const scrollMeasurementElement = useRef(null);
  const [measurementSearchTerm, setMeasurementSearchTerm] = useState('');
  const [stations, setStations] = useState([]);
  const [stationsSearchResults, setStationSearchResults] = useState([]);
  const [measurementsSearchResults, setMeasurementsSearchResults] = useState(
    []
  );
  const [
    stationAtMeasurementPageSelected,
    setStationAtMeasurementPageSelected,
  ] = useState(false);
  const { stationStore, measurementStore, uiStore } = useStores();
  const currentPage = window.location.pathname;
  const measurementSelected = currentPage === '/rapportering/matningar';
  const stationSelected = currentPage === '/rapportering/station';
  measurementStore.setScrollMeasurementElement(scrollMeasurementElement);
  useEffect(() => {
    setStationAtMeasurementPageSelected(
      measurementSelected && stationStore.selectedStation
    );
    if (
      (stations.length === 0 && stationsSearchResults.length === 0) ||
      !stationsSearchResults[0]
    ) {
      setStations(stationStore.stations);
      return;
    }
    if (stationsSearchResults && stationsSearchResults.length > 0) {
      setStations(stationsSearchResults);
    }
    if (
      uiStore.createdStationSearchResult &&
      uiStore.createdStationSearchResult.length > 0
    ) {
      setSearchTerm(uiStore.createdStationSearchResult[0]);
    }
    if (!stationAtMeasurementPageSelected) {
      setMeasurementSearchTerm('');
    }
    if (stationStore.selectedStation) {
      uiStore.setMeasurementSearchResults(measurementsSearchResults);
    }
  }, [
    measurementSelected,
    measurementsSearchResults,
    setSearchTerm,
    stationAtMeasurementPageSelected,
    stationStore.selectedStation,
    stationStore.stations,
    stations.length,
    stationsSearchResults,
    uiStore,
  ]);

  const reactTableHeader = [
    {
      Header: 'Namn på mätstation',
      accessor: 'name',
    },
    {
      Header: 'Stationskod',
      accessor: 'natlStationCode',
    },
    {
      Header: 'Organisation',
      accessor: 'organisationName',
    },
    {
      Header: 'Startdatum',
      accessor: 'beginTime',
    },
    {
      Header: 'Slutdatum',
      accessor: 'endTime',
    },
  ];

  const setStation = (data) => {
    stationStore.clearMessage(FORMS.STATIONS);
    if (data) {
      stationStore.setSelectedStation(data, keycloak.token);
      measurementStore.setSelectedMeasurement(null);
      setSearchTerm(data.name);
    } else {
      stationStore.setSelectedStation(null);
      measurementStore.setSelectedMeasurement(null);
      setSearchTerm('');
    }
    executeScrollToElement(scrollElement);
  };

  return (
    <>
      <div id="station-list">
        <div className="top">
          <div className="station-search-width">
            <SearchBar
              setSelectedContent={setSelectedContent}
              setSearchResults={setStationSearchResults}
              searchEntity={ENTITY.STATIONS}
              placeholder="Station, kod, org, datum"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              label="Sök station"
              filterValue={selectedContent.value}
            />
          </div>
          {stationAtMeasurementPageSelected && (
            <div className="measurement-search-width">
              <SearchBar
                setSelectedContent={setSelectedContent}
                setSearchResults={setMeasurementsSearchResults}
                searchEntity={ENTITY.MEASUREMENTS}
                placeholder="Ämne, observation-id, datum"
                searchTerm={measurementSearchTerm}
                setSearchTerm={setMeasurementSearchTerm}
                label="Sök mätning"
                filterValue={selectedContent.value}
              />
            </div>
          )}
          <div className="right-align">
            <ButtonGroup
              selectedContent={selectedContent}
              setSelectedContent={setSelectedContent}
              buttons={[
                SELECTED_CONTENT.ALL,
                SELECTED_CONTENT.ACTIVE,
                SELECTED_CONTENT.INACTIVE,
              ]}
            />
            {stationAtMeasurementPageSelected && (
              <Button
                className="add"
                icon={<AddOutline />}
                variant={Button.variants.secondary}
                label="Ny mätning"
                onClick={async () => {
                  stationStore.clearMessages();
                  measurementStore.clearMessages();
                  measurementStore.setSelectedMeasurement({
                    ...emptyMeasurement,
                  });
                  setMeasurementSearchTerm('');
                  setStationSearchResults([]);
                  executeScrollToElement(
                    measurementStore.scrollMeasurementElement
                  );
                  await measurementStore.setDisablePublish(
                    measurementStore.selectedMeasurement,
                    stationStore.selectedStation,
                    stationStore.samplingEquipments,
                    stationStore.measurementEquipments,
                    stationStore.samplingMethods,
                    stationStore.measurementMethods
                  );
                }}
              ></Button>
            )}
            {stationSelected && (
              <Button
                className="add"
                icon={<AddOutline />}
                variant={Button.variants.secondary}
                label="Ny mätstation"
                onClick={async () => {
                  stationStore.clearMessages();
                  measurementStore.clearMessages();
                  stationStore.setStation({ ...emptyStation });
                  measurementStore.setSelectedMeasurement(null);
                  setSearchTerm('');
                  executeScrollToElement(scrollElement);
                  await stationStore.setDisablePublish(
                    stationStore.selectedStation
                  );
                }}
              ></Button>
            )}
          </div>
        </div>
        {stations && !hide && (
          <>
            <Grid
              reactTableHeader={reactTableHeader}
              gridData={[...stations].filter((station) => {
                if (uiStore.selectedContent.value === 0) return true;
                if (uiStore.selectedContent.value === 6) {
                  return !isInactive(station.endTime);
                }
                if (uiStore.selectedContent.value === 5) {
                  return isInactive(station.endTime);
                }
              })}
              onRowClick={setStation}
              gridEntity={ENTITY.STATIONS}
            />
          </>
        )}
      </div>
      <span ref={scrollElement}> </span>
    </>
  );
});

StationList.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default StationList;
