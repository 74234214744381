import React, { useState, useEffect, useRef } from 'react';
import { List, ListItem, TextInput } from '@aurora/widgets-react';
import './SearchBar.css';
import { useStores } from '../../hooks/useStores';
import {
  SearchOutline,
  WeatherStationOutline,
  CloseOutline,
} from '@aurora/icons-react';
import { useKeycloak } from '@react-keycloak/web';

const NO_RESULT = {
  highlight: false,
  noIcon: true,
  name: 'Inga träffar',
};

const LOADING = {
  highlight: false,
  noIcon: true,
  name: 'Laddar ...',
};

const ERROR = {
  highlight: false,
  noIcon: true,
  name: '',
};

const SearchWithResult = () => {
  const { keycloak } = useKeycloak();
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isFocus, setFocus] = useState(false);
  const { stationStore, measurementStore, uiStore } = useStores();
  const searchListRef = useRef(null);
  const searchInputRef = useRef(null);
  let timer = null;

  const handleClose = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
    setSearchTerm('');
    setSelectedItem(null);
    setSearchResults([]);
    stationStore.setStation(null);
    measurementStore.setSelectedMeasurement(null);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleClose();
    }
  };

  const handleClickOutside = (e) => {
    if (
      (searchListRef.current === null ||
        !searchListRef.current.contains(e.target)) &&
      (searchInputRef.current === null ||
        !searchInputRef.current.contains(e.target))
    ) {
      setFocus(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    const setStationResult = () => {
      return stationStore.stations.filter(
        (s) =>
          (s.name && s.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (s.natlStationCode &&
            s.natlStationCode
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (s.organisationName &&
            s.organisationName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (s.beginTime &&
            s.beginTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (s.endTime &&
            s.endTime.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    };
    const fetchData = async () => {
      let array = [LOADING];
      setActiveIndex(null);
      setSearchResults(array);
      const result = setStationResult();

      if (result.length === 0) {
        array = [NO_RESULT];
        setSearchResults(array);
      } else if (result.error) {
        ERROR.name = result.error;
        array = [ERROR];
        setSearchResults(array);
      } else {
        setSearchResults(result);
      }
    };
    if (searchTerm && searchTerm !== '') {
      fetchData();
    }
    return function cleanup() {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [searchTerm, stationStore.stations]);

  const handleSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchTerm(value);
    }, 500);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const setSearchItem = (item) => {
    setSelectedItem(item);
    setFocus(false);
    searchInputRef.current.value = item.name;
    searchInputRef.current.blur();
    stationStore.setSelectedStation(item, keycloak.token);
    uiStore.navigate('/rapportering/station');
  };

  const RenderSearchSuggestions = () => {
    if (searchResults.length === 0 || isFocus === false) {
      return null;
    }

    if (searchResults.error) {
      return <p>{searchResults.error}</p>;
    }

    return (
      <div ref={searchListRef}>
        <List className="search--suggestions" id="search--suggestions">
          {searchResults.map((item, index) => {
            const secondaryText = `${item.organisationName || ''} ${
              item.natlStationCode || ''
            } `;

            return (
              <ListItem
                tabIndex="0"
                aria-label={item.name}
                role="button"
                onClick={() => {
                  setSearchItem(item);
                }}
                key={`${index}`}
                content={item.name}
                selected={item === selectedItem}
                dividerBottom
                {...(activeIndex === index && { id: 'active-search-item' })}
                {...(!item.highlight && { active: activeIndex === index })}
                {...(!item.noIcon && { iconLeft: <WeatherStationOutline /> })}
                {...(secondaryText && { line2: secondaryText })}
              ></ListItem>
            );
          })}
        </List>
      </div>
    );
  };

  return (
    <>
      <div id="search-bar" style={{ maxWidth: '300px' }}>
        <TextInput
          label="Sök station"
          ref={searchInputRef}
          id="search-input"
          name="search-input"
          className="search-input"
          type="text"
          role="searchbox"
          placeholder="Station, kod, org, datum"
          onChange={handleSearch}
          onFocus={handleFocus}
          defaultValue={searchTerm}
          icon={
            <>
              <CloseOutline
                className="close-icon"
                size="m"
                aria-label="Avbryt sökning"
                tabIndex="0"
                onClick={handleClose}
                onKeyDown={handleKeyDown}
              />
              <SearchOutline className="search-icon-with-result" size="m" />
            </>
          }
        />
        <RenderSearchSuggestions />
      </div>
    </>
  );
};

export default SearchWithResult;
