import React from 'react';
import propTypes from 'prop-types';
import './AdminContainer.css';
import { H1, Body1 } from '@aurora/widgets-react';
import LinkMenu from '../../LinkMenu';
import MenuItem from '../../SideMenu/MenuItem';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import AdminText from '../../../AdminText';

const AdminContainer = observer(({ header, type, children }) => {
  const currentPage = window.location.pathname;
  const { uiStore } = useStores();

  return (
    <>
      <div id="admin-container">
        <H1 tabIndex="0">{header}</H1>
        <Body1 tabIndex="0" className="text">
          <AdminText pageType={type} />
        </Body1>
        <LinkMenu maxwidth={42}>
          <MenuItem
            text="Nätverk och dokument"
            onClick={() => {
              uiStore.navigate('/rapportering/uppgifter/natverk');
            }}
            isStationMenu
            selected={currentPage === '/rapportering/uppgifter/natverk'}
          />

          <MenuItem
            text="Organisation"
            onClick={() => {
              uiStore.navigate('/rapportering/uppgifter/organisation');
            }}
            isStationMenu
            selected={currentPage === '/rapportering/uppgifter/organisation'}
          />
          <MenuItem
            text="Användare"
            onClick={() => {
              uiStore.navigate('/rapportering/uppgifter/anvandare');
            }}
            isStationMenu
            selected={currentPage === '/rapportering/uppgifter/anvandare'}
          />
        </LinkMenu>
        <hr />
        {children}
      </div>
    </>
  );
});

AdminContainer.propTypes = {
  header: propTypes.node,
  type: propTypes.string,
  children: propTypes.node,
};

export default AdminContainer;
