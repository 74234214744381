import { bearerAuth } from '../utils/funcs';

export async function getStations(token) {
  try {
    const response = await fetch(`/api/stations`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av stationer misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av stationer misslyckades' };
  }
}

export async function getStation(id, token) {
  try {
    const response = await fetch(`/api/station/${id}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();

    if (!response.ok) {
      return {
        error: 'Hämtningen av utökad stationsinformation misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av utökad stationsinformation misslyckades' };
  }
}

export async function updateStation(station, url, token) {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(station),
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att uppdatera aktuell station',
      };
    }
  } catch (e) {
    return { error: 'Det gick inte att uppdatera aktuell station' };
  }
}

export async function insertStation(station, token) {
  try {
    const response = await fetch(`/api/station`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(station),
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att skapa aktuell station',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att skapa aktuell station' };
  }
}

export async function publish(id, published, token) {
  try {
    const response = await fetch(
      `/api/station/publish/${id}?published=${published}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: bearerAuth(token),
        },
      }
    );
    if (!response.ok) {
      return {
        error: 'Det gick inte att publicera aktuell station',
      };
    }
  } catch (e) {
    return { error: 'Det gick inte att publicera aktuell station' };
  }
}

export async function deleteStation(id, token) {
  try {
    const response = await fetch(`/api/station/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
    });
    if (!response.ok) {
      return {
        error: 'Det gick inte att ta bort aktuell station',
      };
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return { error: 'Det gick inte att ta bort aktuell station' };
  }
}
