import { bearerAuth, convertToQuery } from '../utils/funcs';

export async function getEureport(token, queryObject) {
  try {
    const END_POINT = '/api/eureport';
    const query = convertToQuery(queryObject);
    const response = await fetch(`${END_POINT}${query ? `?${query}` : ''}`, {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av eurapport misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av eurapport misslyckades' };
  }
}

export async function getYears(token) {
  try {
    const response = await fetch('/api/years', {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av eurapportens år misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av eurapportens år misslyckades' };
  }
}

export async function getAssesmentregimes(token) {
  try {
    const response = await fetch('/api/assesmentregimes', {
      headers: { Authorization: bearerAuth(token) },
    });
    const json = await response.json();
    if (!response.ok) {
      return {
        error: 'Hämtningen av assesmentregimes misslyckades',
      };
    }
    return json;
  } catch (e) {
    return { error: 'Hämtningen av assesmentregimes misslyckades' };
  }
}

export async function updateAssesmentregimesSamplingPoint(
  token,
  assesmentregimeSamplingpoints
) {
  const END_POINT = '/api/updateassesmentregimessamplingPoint';
  try {
    const response = await fetch(`${END_POINT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(assesmentregimeSamplingpoints),
    });
    if (!response.ok) {
      return {
        error:
          'Det gick inte att skapa uppdatera assesmentregimeSamplingpoints',
      };
    }
  } catch (e) {
    return {
      error: 'Det gick inte att skapa uppdatera assesmentregimeSamplingpoints',
    };
  }
}
