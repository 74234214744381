import React from 'react';
import PropTypes from 'prop-types';
import './Loading.css';

const Loading = ({ isLoading, text }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <>
      <h4>{text || 'Loading data...'}</h4>
      <div className="flash-container" data-title=".dot-flashing">
        <div className="dot-flashing"></div>
      </div>
    </>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string,
};

export default Loading;
