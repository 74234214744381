import { makeObservable, observable, action } from 'mobx';
import { getRealtimeData } from '../api/realtimeData';

export class RealtimeDataStore {
  realtimeData = [];
  initialErrorMessage = null;

  constructor(rootStore) {
    makeObservable(this, {
      initialErrorMessage: observable,
      getRealtimeData: action,
      realtimeData: observable,
    });

    this.rootStore = rootStore;
  }

  setErrorMessage = (error) => {
    this.initialErrorMessage = error;
  };

  clearMessages = () => {
    this.initialErrorMessage = null;
  };

  getRealtimeData = async (token) => {
    const response = await getRealtimeData(token);
    if (!response || response.error) {
      const errorMessage =
        response.error || 'Något gick fel vid hämtning av realtidsdata';
      this.initialErrorMessage = errorMessage;
    } else {
      this.realtimeData = response;
    }
  };
}

// decorate(RealtimeDataStore, {
//   initialErrorMessage: observable,
//   getRealtimeData: action,
//   realtimeData: observable,
// });
