import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import page from 'page';
import StationsContainer from './components/Station/StationsContainer';
import AdminContainer from './components/Admin/AdminContainer';
import ReportContainer from './components/Report/ReportContainer';
import AuditContainer from './components/Audit/AuditContainer';
import RealtimeDataContainer from './components/RealtimeData/RealtimeDataContainer';
import EureportContainer from './components/Eureport/EureportContainer';
import AppRoot from './components/AppRoot';
import AppLoading from './components/AppLoading';
import PageNotFound from './pages/NotFound';
import reportWebVitals from './reportWebVitals';
import './index.css';
import keycloakConfig from './Keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
// import { START_TEXT } from './welcomeTexts';

// Code-splitting, lazy load pages
const StartPage = lazy(() => import('./pages/Start'));
const MeasuringStationPage = lazy(() => import('./pages/MeasuringStation'));
const MeasurementPage = lazy(() => import('./pages/Measurement'));
const StatusPage = lazy(() => import('./pages/Status'));
const UserPage = lazy(() => import('./pages/User'));
const OrganisationListPage = lazy(() => import('./pages/Organisation'));
const NetworkListPage = lazy(() => import('./pages/Network'));
const AuditPage = lazy(() => import('./pages/Audit/Audit'));
const AuditCalculatorPage = lazy(() => import('./pages/Audit/AuditCalculator'));
const ReportStatusPage = lazy(() => import('./pages/Report/Overview'));
const ReportMeasurementPage = lazy(() =>
  import('./pages/Report/ReportMeasurement')
);
const ReportMetaPage = lazy(() => import('./pages/Report/ReportMeta'));
const RealTimeDataPage = lazy(() => import('./pages/RealtimeData'));
const EureportPage = lazy(() => import('./pages/Eureport'));

const render = (c, name) => {
  ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloakConfig}>
      <AppRoot>
        <Suspense
          fallback={
            <AppLoading message={name ? `Laddar ${name}...` : 'Laddar...'} />
          }
        >
          {c}
        </Suspense>
      </AppRoot>
    </ReactKeycloakProvider>,
    document.getElementById('root')
  );
};

// Render page contained in a map
const renderStationsContainer = (c, name, type) => {
  render(
    <StationsContainer header={name} type={type}>
      {c}
    </StationsContainer>,
    name
  );
};

const renderReportContainer = (c, name, type = '') => {
  render(
    <ReportContainer header={name} type={type}>
      {c}
    </ReportContainer>,
    name
  );
};

const renderAdminContainer = (c, name, type = '') => {
  render(
    <AdminContainer header={name} type={type}>
      {c}
    </AdminContainer>,
    name
  );
};

const renderAuditContainer = (c, name, type = '') => {
  render(
    <AuditContainer header={name} type={type}>
      {c}
    </AuditContainer>,
    name
  );
};

const renderRealtimeDataContainer = (c, name, type = '') => {
  render(
    <RealtimeDataContainer header={name} type={type}>
      {c}
    </RealtimeDataContainer>,
    name
  );
};

const renderEureportContainer = (c, name, type = '') => {
  render(
    <EureportContainer header={name} type={type}>
      {c}
    </EureportContainer>,
    name
  );
};

page('/rapportering', () =>
  renderStationsContainer(
    <StartPage />,
    'Rapportering av luftkvalitetsdata',
    'start'
  )
);

page('/rapportering/station', () =>
  renderStationsContainer(
    <MeasuringStationPage />,
    'Mätstationer',
    'stationinfo'
  )
);

page('/rapportering/matningar', () =>
  renderStationsContainer(<MeasurementPage />, 'Mätningar', 'measurement')
);

page('/rapportering/status', () =>
  renderStationsContainer(<StatusPage />, 'Status', 'status')
);

page('/rapportering/rapportera/oversikt', () =>
  renderReportContainer(<ReportStatusPage />, 'Översikt och mallar', 'overview')
);

page('/rapportering/rapportera/laddaupp', () =>
  renderReportContainer(
    <ReportMeasurementPage />,
    'Ladda upp mätdata',
    'reportMeasurement'
  )
);

page('/rapportering/rapportera/metadata', () =>
  renderReportContainer(<ReportMetaPage />, 'Årliga metadata', 'reportMeta')
);

page('/rapportering/uppgifter/anvandare', () =>
  renderAdminContainer(<UserPage />, 'Användare', 'user')
);

page('/rapportering/uppgifter/organisation', () =>
  renderAdminContainer(<OrganisationListPage />, 'Organisation', 'organisation')
);

page('/rapportering/uppgifter/natverk', () =>
  renderAdminContainer(<NetworkListPage />, 'Nätverk och dokument', 'network')
);

page('/rapportering/audit/databastabeller', () =>
  renderAuditContainer(<AuditPage />, 'Databastabeller', 'audit')
);

page('/rapportering/audit/statistikberakningar', () =>
  renderAuditContainer(
    <AuditCalculatorPage />,
    'Statistikberäkningar',
    'auditCalculator'
  )
);

page('/rapportering/realtidsdata/status', () =>
  renderRealtimeDataContainer(<RealTimeDataPage />, 'Status', 'status')
);

page('/rapportering/eurapportering/assessmentregimes', () =>
  renderEureportContainer(
    <EureportPage />,
    'Assessmentregimes/samplingpoints',
    'eureport'
  )
);

page('*', () => render(<PageNotFound />));

page({ click: false });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
