import * as Yup from 'yup';

export const metaSchema = (selectedMetadata) => {
  return Yup.object().shape({
    measurementType: Yup.string()
      .nullable()
      .min(0, 'Typ av mätning måste anges')
      .required('Typ av mätning måste anges'),
    timeCoverage: Yup.number()
      .nullable()
      .typeError('Tidstäckning måste vara ett nummer')
      .test({
        message: 'Tidstäckning måste anges',
        test: function (value) {
          if (selectedMetadata.timeCoverage === '') {
            return true;
          } else {
            return value !== undefined;
          }
        },
      }),
    // .test({
    //   message: 'Datafångsten får inte bli > 100%',
    //   test: function (value) {
    //     if (
    //       this.parent.dataCapture === 100 &&
    //       value !== selectedMetadata.timeCoverage
    //     ) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    // }),
    teomFunction: Yup.string()
      .nullable()
      .when('isTeom', {
        is: (val) => {
          return val;
        },
        then: Yup.string()
          .nullable()
          .min(0, 'TEOM-funktion måste anges')
          .required('TEOM-funktion måste anges'),
      }),
    purpose: Yup.string()
      .nullable()
      .required('Syfte med kontrollen måste anges'),
  });
};

export const reportSchema = Yup.object().shape({
  report: Yup.object().shape({
    contact: Yup.string()
      .nullable()
      .min(0, 'Kontakt måste anges')
      .required('Kontakt måste anges'),
  }),
});
