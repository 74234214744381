import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  validateReport,
  submitReport,
  getMetaData,
  getStationsObservationsStatus,
  getObservationsStatus,
  insertMetadata,
  updateMetadata,
  publishMetadata,
  downloadTemplate,
  downloadTemplates,
  getAssessmentType,
  downloadArchiveFiles,
} from '../api/report';
import {
  emptyStationObservations,
  emptyObservationMetaDatas,
  convertNullPropsToEmptyString,
} from '../utils/funcs';
import { REPORT_VALID_RESULT, REPORT_STEP, REPORT_STATUS } from '../utils/enum';
import { getYear, subYears, format } from 'date-fns';
import { sendMail } from '../api/notification';

export class ReportStore {
  report = {
    contact: '',
    comment: '',
    files: [],
  };
  selectedMetaData = null;
  stationsObservations = null;
  reportResult = [];
  selectedReportResult = null;
  selectedStationObservations = null;
  selectedPollutantTemplates = [];
  selectedTemplates = [];
  observationsStatus = [];
  selectedObservationStatus = null;
  selectedMetaDatas = [];
  selectedYear = getYear(subYears(new Date(), 1));
  teomFunction = [];
  calibration = [];
  initialErrorMessage = null;
  formErrorMessage = null;
  emailErrorMessage = null;
  formSuccessMessage = null;
  copyErrorMessage = null;
  copySuccessMessage = null;
  downloadErrorMessage = null;
  downloadSuccessMessage = null;
  downloadArchiveErrorMessage = null;
  downloadArchiveSuccessMessage = null;
  receiptText = null;
  receiptList = [];
  reportStep = REPORT_STEP.ONE;

  constructor(rootStore) {
    makeObservable(this, {
      selectedYear: observable,
      setSelectedYear: action,
      selectedMetaData: observable,
      selectedObservationStatus: observable,
      setSelectedObservationStatus: action,
      observationsStatus: observable,
      stationsObservations: observable,
      selectedStationObservations: observable,
      getStationsObservationsStatus: action,
      report: observable,
      initialErrorMessage: observable,
      formErrorMessage: observable,
      formSuccessMessage: observable,
      reportResult: observable,
      setReportResult: action,
      selectedReportResult: observable,
      setSelectedReportResult: action,
      setSelectedObservationMetaData: action,
      downloadErrorMessage: observable,
      downloadSuccessMessage: observable,
      downloadArchiveErrorMessage: observable,
      downloadArchiveSuccessMessage: observable,
      reportStep: observable,
      setReportStep: action,
      setReportContact: action,
      setReportComment: action,
      setErrorMessage: action,
      setEmailErrorMessage: action,
      setSuccessMessage: action,
      setCopyErrorMessage: action,
      setDownloadErrorMessage: action,
      setCopySuccessMessage: action,
      setDownloadSuccessMessage: action,
      setStationsObservations: action,
      setReport: action,
      setSelectedStationObservations: action,
      setStationObservations: action,
      setObservationsStatus: action,
      setSelectedMetaData: action,
      setFiles: action,
      pushFile: action,
      setDownloadArchiveErrorMessage: action,
      setDownloadArchiveSuccessMessage: action,
      downloadArchiveFiles: action,
    });

    this.rootStore = rootStore;
  }

  setErrorMessage = (error) => {
    this.formErrorMessage = error;
  };

  setEmailErrorMessage = (error) => {
    this.emailErrorMessage = error;
  };

  setSuccessMessage = (success) => {
    this.formSuccessMessage = success;
  };

  setCopyErrorMessage = (error) => {
    this.copyErrorMessage = error;
  };

  setDownloadErrorMessage = (error) => {
    this.downloadErrorMessage = error;
  };

  setDownloadSuccessMessage = (success) => {
    this.downloadSuccessMessage = success;
  };

  setDownloadArchiveErrorMessage = (error) => {
    this.downloadArchiveErrorMessage = error;
  };

  setDownloadArchiveSuccessMessage = (success) => {
    this.downloadArchiveSuccessMessage = success;
  };

  setCopySuccessMessage = (success) => {
    this.copySuccessMessage = success;
  };

  setFiles = (files) => {
    this.report.files = files;
  };

  pushFile = (currentFile) => {
    this.report.files.push(currentFile);
  };

  clearMessages = () => {
    this.setErrorMessage(null);
    this.setSuccessMessage(null);
    this.setCopyErrorMessage(null);
    this.setCopySuccessMessage(null);
    this.setDownloadSuccessMessage(null);
    this.setDownloadErrorMessage(null);
    this.setDownloadArchiveErrorMessage(null);
    this.setDownloadArchiveSuccessMessage(null);
  };

  setReportResult = (reportResult) => {
    if (!reportResult) return;
    this.reportResult = reportResult;
  };

  setReportComment = (comment) => {
    if (!comment) return;
    this.report.comment = comment;
  };

  setReportContact = (contact) => {
    if (!contact) return;
    this.report.contact = contact;
  };

  setReportStep = (reportStep) => {
    if (!reportStep) return;
    this.reportStep = reportStep;
  };

  setSelectedReportResult = (selectedReportResult) => {
    this.selectedReportResult = selectedReportResult;
  };

  setSelectedYear = (selectedYear) => {
    this.setStationsObservations(null);
    if (!selectedYear) return;
    this.selectedYear = selectedYear;
  };

  setSelectedObservationStatus = (selectedObservationStatus) => {
    if (!selectedObservationStatus) return;
    this.selectedObservationStatus = selectedObservationStatus;
  };

  setReport = (report) => {
    this.report = report;
  };

  setStationObservations = (stationObservations) => {
    this.selectedStationObservations = stationObservations;
  };

  setSelectedMetaData = (selectedMetaData) => {
    this.selectedMetaData = selectedMetaData;
  };

  async setSelectedObservationMetaData(year, token) {
    this.setErrorMessage(null);
    const resp = { year: year, token: token };
    if (resp && resp.error) {
      this.setErrorMessage(resp.error || 'Något gick fel under bekräftelsen');
    } else {
      runInAction(async () => {
        this.reportResult = resp;
      });
    }
  }

  async sendEmail(receiver, message, token) {
    if (this.formErrorMessage) return;
    const response = await sendMail(receiver, message, token);
    if (response && response.error) {
      this.setEmailErrorMessage(
        response.error || `Det gick inte att skicka epost till ${receiver}`
      );
    }
  }

  setStationsObservations = (stationsObservations) => {
    this.stationsObservations = stationsObservations;
  };

  setObservationsStatus = (observationsStatus) => {
    this.observationsStatus = observationsStatus;
  };

  async getStationsObservationsStatus(
    selectedYear,
    token,
    setReportStations,
    setLoading
  ) {
    const resp = await getStationsObservationsStatus(
      {
        year: selectedYear,
      },
      token
    );
    if (resp && resp.error) {
      this.setErrorMessage(
        resp.error ||
          'Något gick fel under hämtning av stationers observationers status'
      );
      return;
    }
    runInAction(async () => {
      this.teomFunction = resp.teomFunction;
      this.calibration = resp.calibration;

      this.setStationsObservations(resp.stationsObservations);
      setReportStations(resp.stationsObservations);
      setLoading(false);
    });
  }

  async getMetaData(obsId, selectedYear, token, setEnablePublish) {
    const resp = await getMetaData(
      {
        observationid: obsId,
        year: selectedYear,
      },
      token
    );
    const assementTypeResp = await getAssessmentType(
      {
        observationid: obsId,
      },
      token
    );
    if ((resp && resp.error) || (assementTypeResp && assementTypeResp.error)) {
      this.setErrorMessage(
        resp.error || 'Något gick fel under hämtning av observationens metadata'
      );
      return;
    }
    runInAction(async () => {
      this.setSelectedMetaData(null);
      if (resp.observationMetadatas.length !== 0) {
        resp.observationMetadatas.forEach((metaData) => {
          if (metaData.year.toString() === selectedYear.toString()) {
            convertNullPropsToEmptyString(metaData);
            this.setSelectedMetaData(metaData);
            setEnablePublish(true);
          }
        });
        if (!this.selectedMetaData) {
          this.setSelectedMetaData(
            emptyObservationMetaDatas(this.selectedYear, assementTypeResp)
          );
          setEnablePublish(false);
        }
        this.selectedMetaDatas = resp.observationMetadatas;
        this.selectedMetaData.isTeom = this.selectedObservationStatus.isTeom;
      } else {
        this.setSelectedMetaData(
          emptyObservationMetaDatas(this.selectedYear, assementTypeResp)
        );
        setEnablePublish(false);
      }
    });
  }

  async setSelectedStationObservations(data, selectedYear, token, setLoading) {
    this.setErrorMessage(null);
    if (!data) return;
    this.setStationObservations(data);
    this.selectedTemplates = [data.templateClassification];

    if (data.maxStatus === 0) {
      this.setStationObservations(emptyStationObservations);
    } else {
      const resp = await getObservationsStatus(
        {
          natlstationcode: data.natlStationCode,
          year: selectedYear,
          templateclassification: data.templateClassification,
        },
        token
      );
      if (resp && resp.error) {
        this.setErrorMessage(
          resp.error ||
            'Något gick fel under hämtning av stationens observationers status'
        );
        return;
      }
      runInAction(async () => {
        this.setObservationsStatus(resp.observationsStatus);
        setLoading(false);
      });
    }
  }

  async submitReport(token) {
    if (!this.reportResult || this.reportResult.length === 0) {
      this.setErrorMessage('Det finns inga filer att rapportera');
      return;
    }
    this.formErrorMessage = null;
    const files = this.reportResult
      .filter((result) => {
        return (
          result.validResult === REPORT_VALID_RESULT.VALID ||
          result.validResult === REPORT_VALID_RESULT.CONFIRMED
        );
      })
      .map((res) => {
        return this.report.files.find((file) => file.name === res.fileName);
      });
    let formData = new FormData();
    formData.append('contact', this.report.contact);
    formData.append('comment', this.report.comment);
    files.forEach((file) => {
      formData.append('files', file);
    });
    const resp = await submitReport(formData, token);
    if (resp && resp.error) {
      this.setErrorMessage(resp.error || 'Något gick fel under rapporteringen');
    } else {
      runInAction(async () => {
        this.receiptList = resp;
        this.setSuccessMessage('Rapporteringen genomförd');
        const receiptArray = [];
        this.pushFormatedReceitInfo(receiptArray);
        this.receiptText = `Kvitto \r\nRapporterad mätdata ${format(
          new Date(),
          'yyyy-MM-dd HH:mm'
        )} \r\n \r\n${receiptArray.join('\r\n')}`;
      });
    }
  }

  pushFormatedReceitInfo(receiptArray) {
    Object.keys(this.reportResult).forEach((item) => {
      receiptArray.push(
        `Mätstation: ${this.reportResult[item].station}, Stationskod: ${this.reportResult[item].code}, Filnamn: ${this.reportResult[item].fileName}, Validering: ${this.reportResult[item].validResult}`
      );
      this.receiptList
        .filter((element) => {
          return element.natlstationcode === this.reportResult[item].code;
        })
        .forEach((measurement) => {
          receiptArray.push(
            `Mätning: ${measurement.pollutantNotation}, Observationsid: ${measurement.observationid}, Startvärde: ${measurement.firstUploadDate}, Slutvärde: ${measurement.lastUploadDate}, Antal: ${measurement.quantity}`
          );
        });
      receiptArray.push('\r');
    });
  }

  reportReslutHasErros() {
    return Object.keys(this.reportResult).some((item) => {
      return (
        this.reportResult[item].validResult === REPORT_VALID_RESULT.NOT_VALID
      );
    });
  }

  reportReslutHasWarnings() {
    return Object.keys(this.reportResult).some((item) => {
      return (
        this.reportResult[item].validResult === REPORT_VALID_RESULT.WARNING
      );
    });
  }

  createFormatedMailInfo() {
    const resultArray = [];
    Object.keys(this.reportResult).forEach((item) => {
      resultArray.push(
        `Fil "${this.reportResult[item].fileName}" har laddats upp till datavärdskapet för luftkvalitet \r\nmed följande station och föroreningar.\r\n\r\n${this.reportResult[item].station} \r\n`
      );
      this.receiptList
        .filter((element) => {
          return element.natlstationcode === this.reportResult[item].code;
        })
        .forEach((measurement) => {
          resultArray.push(`${measurement.pollutantNotation}`);
        });
      resultArray.push('\r');
    });
    return `Rapporterad mätdata ${format(
      new Date(),
      'yyyy-MM-dd HH:mm'
    )} \r\n\r\n${resultArray.join(
      '\r\n'
    )}\r\nData har skickats för granskning av Reflab. Glöm inte att rapportera in årliga metadata! \r\nVid frågor kontakta datavardluft@smhi.se`;
  }

  async validateReport(token, setDisableReport) {
    this.clearMessages();
    const reportResult = await validateReport(this.report, token);
    if (!reportResult || reportResult.error) {
      this.setErrorMessage(
        reportResult.error || 'Något gick fel när rapporten validerades'
      );
    } else {
      runInAction(async () => {
        this.setReportResult(reportResult);
        if (this.reportReslutHasErros() || this.reportReslutHasWarnings()) {
          setDisableReport(true);
        }
      });
    }
  }

  async updateMetadata(data, token, metaDataObsId) {
    data.year = data.year ? parseInt(this.selectedYear) : 0;
    data.timeCoverage = data.timeCoverage ? parseInt(data.timeCoverage) : 0;
    this.clearMessages();
    const resp = await updateMetadata(
      {
        obsid: metaDataObsId,
      },
      token,
      data
    );
    if (resp && resp.error) {
      this.setErrorMessage(
        resp.error ||
          'Något gick fel när observationens metadata skulle uppdateras'
      );
    } else {
      this.setSuccessMessage('Observationens metadata uppdaterad');
      runInAction(async () => {
        this.setSelectedMetaData(data);
        this.updateStatusInGui();
      });
    }
    return data;
  }

  async insertMetadata(
    data,
    token,
    metaDataObsId,
    setEnablePublish,
    setIsSaveingInsert
  ) {
    this.clearMessages();
    data.year = data.year ? parseInt(this.selectedYear) : 0;
    const metaData = await insertMetadata(
      {
        obsid: metaDataObsId,
        ismeta: true,
      },
      token,
      data,
      setIsSaveingInsert
    );
    if (metaData && metaData.error) {
      this.setErrorMessage(
        metaData.error ||
          'Något gick fel när observationens metadata skulle skapas'
      );
    } else {
      runInAction(async () => {
        setIsSaveingInsert(true);
        this.setSuccessMessage('Observationens metadata skapad.');
        this.setSelectedMetaData(metaData);
        const status = this.getMetadataStatus();
        this.observationsStatus.forEach((os) => {
          if (
            os.observationId === this.selectedObservationStatus.observationId
          ) {
            const max = this.getMaxStatus(status);
            os.metadataStatus = status;
            os.maxStatus = max;
          }
          if (this.selectedStationObservations.maxStatus === 4) {
            this.setStationsObservations(
              this.stationsObservations.map((obj) => {
                if (obj.name === this.selectedStationObservations.name) {
                  return { ...obj, maxStatus: 3 };
                }
                return obj;
              })
            );
          }
        });
        this.updateStatusInGui();
        await this.getMetaData(
          metaDataObsId,
          this.selectedYear,
          token,
          setEnablePublish
        );
        setEnablePublish(true);
      });
    }
  }

  async publishMetaData(
    setError,
    setSuccess,
    token,
    metaStatusSuperUser = null,
    measurementStatusSuperUser = null
  ) {
    this.clearMessages();
    const metaStatus = !metaStatusSuperUser
      ? this.getMetadataStatus()
      : metaStatusSuperUser;
    const measurementStatus = !measurementStatusSuperUser
      ? this.selectedObservationStatus.measurementStatus
      : measurementStatusSuperUser;
    this.selectedObservationStatus.year = this.selectedYear;
    const resp = await publishMetadata(
      {
        obsid: this.selectedObservationStatus.observationId,
        year: this.selectedYear,
        metastatus: metaStatus,
        measurementstatus: measurementStatus,
      },
      token
    );
    if (resp && resp.error) {
      setError
        ? setError(
            resp.error || 'Något gick fel när stationen skulle publiceras'
          )
        : this.setErrorMessage(
            '',
            resp.error || 'Något gick fel när stationen skulle publiceras'
          );
    } else {
      const resp = await getObservationsStatus(
        {
          natlstationcode: this.selectedStationObservations.natlStationCode,
          year: this.selectedYear,
          templateclassification:
            this.selectedStationObservations.templateClassification,
        },
        token
      );
      if (resp && resp.error) {
        this.setErrorMessage(
          resp.error ||
            'Något gick fel under hämtning av stationens observationers status'
        );
        return;
      }
      runInAction(async () => {
        this.setObservationsStatus(resp.observationsStatus);
      });
      runInAction(async () => {
        this.observationsStatus.forEach((os) => {
          if (
            os.observationId === this.selectedObservationStatus.observationId
          ) {
            os.metadataStatus = metaStatus;
            os.measurementStatus = measurementStatus;
            os.maxStatus = this.getMaxStatusFromSuperUser(
              metaStatus,
              measurementStatus
            );
            this.setSelectedObservationStatus(os);
          }
        });
        this.updateStatusInGui();
        if (metaStatusSuperUser && measurementStatusSuperUser) {
          setSuccess(
            `Status för årlig metadata = ${REPORT_STATUS[metaStatus]}. 
            Status för årlig mätdata = ${REPORT_STATUS[measurementStatus]}`
          );
        } else {
          setSuccess(
            metaStatus === 3 || metaStatus === 4
              ? 'Metadata avpublicerades'
              : 'Metadata publicerades för granskning'
          );
        }
      });
    }
  }

  updateStatusInGui() {
    const everyMaxStatusIsMissing = this.observationsStatus.every(
      (observationsStatus) => observationsStatus.maxStatus === 4
    );
    const max = everyMaxStatusIsMissing
      ? 4
      : Math.max(
          ...this.observationsStatus.map((os) => {
            return os.maxStatus === 5 || os.maxStatus === 6
              ? 1
              : os.maxStatus === 4
              ? 3
              : os.maxStatus;
          })
        );
    this.selectedStationObservations.maxStatus = max;
  }

  getMetadataStatus() {
    if (!this.selectedObservationStatus) {
      return;
    }
    const currentMetaStatus = this.selectedObservationStatus.metadataStatus;
    if (currentMetaStatus === 4) {
      return 3;
    } else {
      return this.selectedObservationStatus.metadataStatus === 3 ? 2 : 3;
    }
  }

  getMaxStatus(status) {
    if (!status) {
      return;
    }
    if (
      (this.selectedObservationStatus.measurementStatus !== 4 &&
        status === 4) ||
      (this.selectedObservationStatus.measurementStatus === 4 && status !== 4)
    ) {
      return 3;
    } else {
      if (this.selectedObservationStatus.measurementStatus > status) {
        return this.selectedObservationStatus.measurementStatus;
      }
      if (this.selectedObservationStatus.measurementStatus <= status) {
        return status;
      }
    }
  }

  getMaxStatusFromSuperUser(metaStatus, measurementStatus) {
    if (!metaStatus || !measurementStatus) {
      return;
    }
    if (measurementStatus === 1 && metaStatus === 1) {
      return 1;
    }
    if (
      (measurementStatus !== 4 && metaStatus === 4) ||
      (measurementStatus === 4 && metaStatus !== 4)
    ) {
      return 3;
    } else {
      if (measurementStatus > metaStatus) {
        return measurementStatus;
      }
      if (measurementStatus <= metaStatus) {
        return metaStatus;
      }
    }
  }

  async downloadTemplate(
    year,
    natlStationCode,
    stationName,
    template,
    token,
    isExtended
  ) {
    this.clearMessages();
    if (!natlStationCode) {
      return;
    }
    const response = await downloadTemplate(
      year,
      natlStationCode,
      stationName,
      template,
      token,
      isExtended
    );
    if (response && response.error) {
      this.setDownloadErrorMessage(
        response.error ||
          `Misslyckades att ladda ner mall för station med Stationskod ${natlStationCode} och år ${year}`
      );
    } else {
      runInAction(async () => {
        if (response && response.status === 204) {
          return;
        }
        const fileName = await response.headers
          .get('Content-Disposition')
          .split('=')[1];
        const url = window.URL.createObjectURL(
          new Blob([await response.blob()])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setDownloadSuccessMessage(`Mallen ${fileName} nedladdad`);
      });
    }
  }

  async downloadTemplates(year, token, isExtended) {
    this.clearMessages();
    if (!year) {
      return;
    }
    const response = await downloadTemplates(year, token, isExtended);
    if (response && response.error) {
      this.setDownloadErrorMessage(
        response.error || `Misslyckades att ladda ner zip-fil för år ${year}`
      );
    } else {
      runInAction(async () => {
        const fileName = await response.headers
          .get('Content-Disposition')
          .split('=')[1];
        const url = window.URL.createObjectURL(
          new Blob([await response.blob()])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link..
        link.parentNode.removeChild(link);
        this.setDownloadSuccessMessage(`Zipmallen ${fileName} nedladdad`);
      });
    }
  }
  async downloadArchiveFiles(year, natlStationCode, adminUnitId, name, token) {
    this.clearMessages();
    const fixedAdminUnitId = this.fixAdminunitId(adminUnitId.toString());
    const response = await downloadArchiveFiles(
      {
        year: year,
        natlstationcode: natlStationCode,
        adminunit: fixedAdminUnitId,
        name: name,
      },
      token
    );
    if (response && response.error) {
      this.setDownloadArchiveErrorMessage(
        `Misslyckades att ladda arkivfil för station ${name} år ${year}`
      );
    } else {
      runInAction(async () => {
        if (response && response.status === 204) {
          this.setDownloadArchiveErrorMessage(
            `Det finns inga filer i arkivet för ${name} år ${year}`
          );
          return;
        }
        const fileName = await response.headers
          .get('Content-Disposition')
          .split('=')[1];
        const url = window.URL.createObjectURL(
          new Blob([await response.blob()])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setDownloadArchiveSuccessMessage(
          `Arkivfilen för ${name} år ${year} nedladdad`
        );
      });
    }
  }
  fixAdminunitId(adminunitId) {
    return adminunitId.length > 3 ? adminunitId : `0${adminunitId}`;
  }
}
