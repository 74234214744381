import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css';
import { SubText } from '@aurora/widgets-react';

const Footer = ({ innerClass = '' }) => {
  return (
    <div id="footer">
      <hr />
      <div className="footer-container" tabIndex="0">
        <div className="center">
          <SubText className={innerClass}>
            Vid frågor eller behov av hjälp kontakta{' '}
            <a href="mailto:rapporteringluftkvalitet@naturvardsverket.se">
              rapporteringluftkvalitet@naturvardsverket.se
            </a>{' '}
            eller <a href="mailto:datavardluft@smhi.se">datavardluft@smhi.se</a>
          </SubText>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  innerClass: PropTypes.string,
};

export default Footer;
