import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Success.css';
import { StatusCard, Button } from '@aurora/widgets-react';
import { CloseOutline } from '@aurora/icons-react';

const Success = ({ success, isStatusPage = false }) => {
  const [close, setClose] = useState(false);

  const closeSuccess = () => {
    setClose(true);
  };

  useEffect(() => {
    if (!success || success === '') {
      setClose(true);
    } else {
      setClose(false);
    }
  }, [success]);

  return (
    <>
      {!close && (
        <div
          id="success-message-container"
          className={`${isStatusPage ? '' : 'padding-bottom'} `}
        >
          <StatusCard
            style={{ whiteSpace: 'pre-line', maxWidth: '100%' }}
            variant={StatusCard.variants.success}
          >
            <div aria-label={success} tabIndex="0">
              {success}
            </div>
          </StatusCard>
          <Button
            variant={Button.variants.tertiary}
            style={{ padding: isStatusPage ? '0px' : '4px' }}
            label=""
            className="close"
            aria-label="stäng"
            onClick={closeSuccess}
            icon={<CloseOutline className="icon" />}
          >
            {' '}
          </Button>
        </div>
      )}
    </>
  );
};

Success.propTypes = {
  success: PropTypes.string,
  isStatusPage: PropTypes.string,
  setSuccess: PropTypes.func,
};

export default Success;
