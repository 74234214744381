import React from 'react';
import { H1, Body1 } from '@aurora/widgets-react';
import {
  RadarOutline,
  EditOutline,
  LogOutOutline,
  AdminOutline,
  InformationOutline,
  TimeOutline,
  GlobeOutline,
  DatabaseOutline,
} from '@aurora/icons-react';
import MenuItem from './MenuItem';
import CollapsableItem from './CollapsableItem';
import './SideMenu.css';
import { useStores } from '../../hooks/useStores';
import { useKeycloak } from '@react-keycloak/web';
import { USER_ROLE } from '../../utils/enum';

const SideMenu = () => {
  const { keycloak } = useKeycloak();
  const { uiStore, authStore, stationStore } = useStores();
  const handleHeaderClick = () => {
    stationStore.setStation(null);
    uiStore.navigate('/rapportering');
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleHeaderClick();
    }
  };

  const currentPage = window.location.pathname;
  const stationSelected =
    currentPage === '/rapportering/station' ||
    currentPage === '/rapportering/matningar' ||
    currentPage === '/rapportering/status';
  const adminSelected =
    currentPage === '/rapportering/uppgifter/anvandare' ||
    currentPage === '/rapportering/uppgifter/organisation';
  const reportSelected =
    currentPage === '/rapportering/rapportera/metadata' ||
    currentPage === '/rapportering/rapportera/laddaupp' ||
    currentPage === '/rapportering/rapportera/oversikt';
  const realtimeDataSelected =
    currentPage === '/rapportering/realtidsdata/status';
  const auditDataSelected =
    currentPage === '/rapportering/audit/databastabeller' ||
    currentPage === '/rapportering/audit/statistikberakningar';
  const euReport =
    currentPage === '/rapportering/eurapportering/assessmentregimes';

  return (
    <div id="side-menu-panel" className="side-menu">
      <nav aria-label="Huvudmeny">
        <div
          role="button"
          className="side-menu--header"
          tabIndex={0}
          onClick={handleHeaderClick}
          onKeyDown={handleKeyDown}
        >
          <H1>RAPPORTERING</H1>
          <Body1>Datavärdskapet för luftkvalitet</Body1>
        </div>
        <div className="side-menu--list">
          <CollapsableItem
            icon={<RadarOutline />}
            text="Stationer och mätningar"
            open={stationSelected}
          >
            <MenuItem
              text="Mätstationer"
              onClick={() => uiStore.navigate('/rapportering/station')}
              selected={
                stationSelected && currentPage === '/rapportering/station'
              }
            />
            <MenuItem
              text="Mätningar"
              onClick={() => uiStore.navigate('/rapportering/matningar')}
              selected={
                stationSelected && currentPage === '/rapportering/matningar'
              }
            />
            <MenuItem
              text="Status"
              onClick={() => uiStore.navigate('/rapportering/status')}
              selected={
                stationSelected && currentPage === '/rapportering/status'
              }
            />
          </CollapsableItem>
          <CollapsableItem
            icon={<EditOutline />}
            text="Rapportera mätdata"
            open={reportSelected}
          >
            <MenuItem
              text="Översikt och mallar"
              onClick={() =>
                uiStore.navigate('/rapportering/rapportera/oversikt')
              }
              selected={
                reportSelected &&
                currentPage === '/rapportering/rapportera/oversikt'
              }
            />
            <MenuItem
              text="Ladda upp mätdata"
              onClick={() =>
                uiStore.navigate('/rapportering/rapportera/laddaupp')
              }
              selected={
                reportSelected &&
                currentPage === '/rapportering/rapportera/laddaupp'
              }
            />
            <MenuItem
              text="Årliga metadata"
              onClick={() =>
                uiStore.navigate('/rapportering/rapportera/metadata')
              }
              selected={
                reportSelected && currentPage === '/rapportering/report/meta '
              }
            />
          </CollapsableItem>
          {authStore.loggedInUser &&
            (authStore.loggedInUser.role === USER_ROLE.SUPERUSER ||
              authStore.loggedInUser.role === USER_ROLE.SYSADMIN) && (
              <CollapsableItem
                icon={<AdminOutline />}
                text="Övriga uppgifter"
                open={adminSelected}
              >
                <MenuItem
                  text="Nätverk och dokument"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate('/rapportering/uppgifter/natverk')
                  }
                />
                <MenuItem
                  text="Organisation"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate('/rapportering/uppgifter/organisation')
                  }
                />
                <MenuItem
                  text="Användare"
                  selected={
                    adminSelected &&
                    currentPage === '/rapportering/uppgifter/anvandare'
                  }
                  onClick={() =>
                    uiStore.navigate('/rapportering/uppgifter/anvandare')
                  }
                />
              </CollapsableItem>
            )}
          {authStore.loggedInUser &&
            (authStore.loggedInUser.role === USER_ROLE.GROUPUSER ||
              authStore.loggedInUser.role === USER_ROLE.GROUPADMIN ||
              authStore.loggedInUser.role === USER_ROLE.NATIONALSUPERVISOR) && (
              <CollapsableItem
                icon={<AdminOutline />}
                text="Övriga uppgifter"
                open={adminSelected}
              >
                <MenuItem
                  text="Nätverk och dokument"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate('/rapportering/uppgifter/natverk')
                  }
                />
                <MenuItem
                  text="Organisation"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate('/rapportering/uppgifter/organisation')
                  }
                />
                <MenuItem
                  text="Användare"
                  selected={
                    adminSelected &&
                    currentPage === '/rapportering/uppgifter/anvandare'
                  }
                  onClick={() =>
                    uiStore.navigate('/rapportering/uppgifter/anvandare')
                  }
                />
              </CollapsableItem>
            )}
          <CollapsableItem
            icon={<TimeOutline />}
            text="Realtidsdata"
            open={realtimeDataSelected}
          >
            <MenuItem
              text="Status"
              selected={false}
              onClick={() =>
                uiStore.navigate('/rapportering/realtidsdata/status')
              }
            />
          </CollapsableItem>
          {authStore.loggedInUser &&
            (authStore.loggedInUser.role === USER_ROLE.SUPERUSER ||
              authStore.loggedInUser.role === USER_ROLE.SYSADMIN) && (
              <CollapsableItem
                icon={<GlobeOutline />}
                text="EU-rapportering"
                open={euReport}
              >
                <MenuItem
                  text="Assessmentregimes/samplingpoints"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate(
                      '/rapportering/eurapportering/assessmentregimes'
                    )
                  }
                />
              </CollapsableItem>
            )}
          {authStore.loggedInUser &&
            (authStore.loggedInUser.role === USER_ROLE.SUPERUSER ||
              authStore.loggedInUser.role === USER_ROLE.SYSADMIN) && (
              <CollapsableItem
                icon={<DatabaseOutline />}
                text="Audit"
                open={auditDataSelected}
              >
                <MenuItem
                  text="Databastabeller"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate('/rapportering/audit/databastabeller')
                  }
                />
                <MenuItem
                  text="Statistikberäkningar"
                  selected={false}
                  onClick={() =>
                    uiStore.navigate('/rapportering/audit/statistikberakningar')
                  }
                />
              </CollapsableItem>
            )}
          <MenuItem
            icon={<InformationOutline />}
            text="Tillgänglighet"
            onClick={() => {
              if (uiStore.disableGui) return;
              window.open('/tillganglighet.html', '_blank');
            }}
          />
          <MenuItem
            icon={<LogOutOutline />}
            text="Logga ut"
            onClick={() => {
              if (uiStore.disableGui) return;
              keycloak.logout();
            }}
          />
        </div>
      </nav>
    </div>
  );
};

export default SideMenu;
