import { bearerAuth } from '../utils/funcs';

export async function sendMail(receiver, message, token) {
  const notification = {
    receiver: receiver,
    message: message,
  };
  try {
    const response = await fetch(`/api/sendmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerAuth(token),
      },
      body: JSON.stringify(notification),
    });
    if (!response.ok) {
      return {
        error: `Det gick inte att skicka epost till ${receiver}`,
      };
    }
  } catch (e) {
    return {
      error: `Det gick inte att skicka epost till ${receiver}`,
    };
  }
}
