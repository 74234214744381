import * as Yup from 'yup';
import { SELECTEDTYPES } from '../../utils/enum';
import { isBeforeDate, isAfterDate, urlRegex } from '../../utils/funcs';

export const measurementSchema = (selectedStation) => {
  return Yup.object().shape({
    organisationName: Yup.string()
      .nullable()
      .min(0, 'Ansvarig organisation för mätning måste anges')
      .required('Ansvarig organisation för mätning måste anges'),
    pollutantNotation: Yup.string()
      .nullable()
      .required('Förorening måste anges'),
    beginTime: Yup.string()
      .nullable()
      .required('Startdatum måste anges')
      .test({
        message: 'Startdatum får inte vara före stationens startdatum',
        test: function (value) {
          const isValid = !isBeforeDate(value, selectedStation.beginTime);
          return isValid;
        },
      })
      .test({
        message: 'Startdatum får inte vara efter stationens slutdatum',
        test: function (value) {
          const isValid = !isAfterDate(value, selectedStation.endTime);
          return isValid;
        },
      }),
    endTime: Yup.string().test({
      message: 'Slutdatum får inte vara efter stationens slutdatum',
      test: function (value) {
        const isValid = !isAfterDate(value, selectedStation.endTime);
        return isValid;
      },
    }),
    primaryObservationTime: Yup.string()
      .nullable()
      .required('Tidsupplösning måste anges'),
  });
};

export const equipmentSchema = () => {
  return Yup.object().shape({
    measurementProcess: Yup.object().shape({
      assessmentType: Yup.string()
        .nullable()
        .min(0, 'Typ av mätning måste anges')
        .required('Typ av mätning måste anges'),
      measurementType: Yup.string()
        .nullable()
        .min(0, 'Mätmetod/provtagningsmetod (typ) måste anges')
        .required('Mätmetod/provtagningsmetod (typ) måste anges'),
      analyticalTechnique: Yup.string()
        .nullable()
        .when('measurementType', {
          is: (val) => {
            return (
              val !== '' &&
              val !== SELECTEDTYPES.REMOTESENSOR &&
              val !== SELECTEDTYPES.AUTOMATICANALYZER
            );
          },
          then: Yup.string()
            .nullable()
            .required('Analytisk teknik måste anges'),
        }),
      measurementMethod: Yup.string()
        .nullable()
        .when('measurementType', {
          is: (val) => {
            return (
              val !== '' &&
              val !== SELECTEDTYPES.ACTIVESAMPLING &&
              val !== SELECTEDTYPES.PASSIVEAMPLING
            );
          },
          then: Yup.string().nullable().required('Mätmetod måste anges'),
        }),
      measurementEquipment: Yup.string()
        .nullable()
        .when('measurementType', {
          is: (val) => {
            return (
              val !== '' &&
              val !== SELECTEDTYPES.ACTIVESAMPLING &&
              val !== SELECTEDTYPES.PASSIVEAMPLING
            );
          },
          then: Yup.string().nullable().required('Mätutrustning måste anges'),
        }),
      samplingMethod: Yup.string()
        .nullable()
        .when('measurementType', {
          is: (val) => {
            return (
              val !== '' &&
              val !== SELECTEDTYPES.REMOTESENSOR &&
              val !== SELECTEDTYPES.AUTOMATICANALYZER
            );
          },
          then: Yup.string().nullable().required('Mätmetod måste anges'),
        }),
      samplingEquipment: Yup.string()
        .nullable()
        .when('measurementType', {
          is: (val) => {
            return (
              val !== '' &&
              val !== SELECTEDTYPES.REMOTESENSOR &&
              val !== SELECTEDTYPES.AUTOMATICANALYZER
            );
          },
          then: Yup.string().nullable().required('Mätutrustning måste anges'),
        }),
      equivalenceDemonstrated: Yup.string()
        .nullable()
        .min(0, 'Likvärdighet med referensmetod måste anges')
        .required('Likvärdighet med referensmetod måste anges'),
      duration: Yup.number().typeError(
        'Mättid måste anges och vara ett nummer'
      ),
      durationUnit: Yup.string()
        .nullable()
        .min(0, 'Mättid (enhet) måste anges')
        .required('Mättid (enhet) måste anges'),
      cadence: Yup.number().typeError(
        'Mätintervall måste anges och vara ett nummer'
      ),
      cadenceUnit: Yup.string()
        .nullable()
        .min(0, 'Mätintervall (enhet) måste anges')
        .required('Mätintervall (enhet) måste anges'),
      documentationRepresentativeness: Yup.string()
        .nullable()
        .test({
          message: 'Måste vara en giltig URL till ett dokument',
          test: function (value) {
            const isValid = !value || value === '';
            if (!isValid) {
              let re = new RegExp(urlRegex);
              return re.test(value);
            } else {
              return isValid;
            }
          },
        }),
      geographicalRepresentativeness: Yup.string()
        .nullable()
        .test({
          message: 'Måste vara en giltig URL till ett dokument',
          test: function (value) {
            const isValid = !value || value === '';
            if (!isValid) {
              let re = new RegExp(urlRegex);
              return re.test(value);
            } else {
              return isValid;
            }
          },
        }),
    }),
  });
};
export const inletSchema = (stationClassification, selectedStation) => {
  return Yup.object().shape({
    inlet: Yup.object().shape({
      latitude: Yup.number()
        .max(10000000, 'N-koordinat måste vara ett tal mellan 0 och 10000000')
        .min(0, 'N-koordinat måste vara ett tal mellan 0 och 10000000')
        .required('N-koordinat måste anges'),
      longitude: Yup.number()
        .max(10000000, 'E-koordinat måste vara ett tal mellan 0 och 10000000')
        .min(0, 'E-koordinat måste vara ett tal mellan 0 och 10000000')
        .required('E-koordinat måste anges')
        .when('srs', {
          is: (val) => {
            return val != SELECTEDTYPES.SWEREF99;
          },
          then: Yup.number().test({
            message: 'E-koordnitat får enbart innehålla 6 siffror',
            test: function (value) {
              if (!value) return false;
              if (selectedStation.longitude !== '') return true;
              const isValid = value.toString().length <= 6;
              return isValid;
            },
          }),
        }),
      srs: Yup.string()
        .min(0, 'Koordinatsystem måste anges')
        .required('Koordinatsystem måste anges'),
      inletHeight: Yup.number()
        .nullable()
        .required('Höjd över mark måste anges')
        .min(0, 'Höjd över mark får inte vara negativt'),
      kerbDistance:
        stationClassification === SELECTEDTYPES.TRAFFIC &&
        Yup.number()
          .typeError('Avstånd från vägkant måste vara ett nummer')
          .required('Avstånd från vägkant måste anges')
          .min(0, 'Avstånd från vägkant får inte vara negativt'),
      buildingDistance:
        stationClassification === SELECTEDTYPES.TRAFFIC &&
        Yup.number()
          .typeError('Avstånd från husfasad måste vara ett nummer')
          .required('Avstånd från husfasad måste anges')
          .min(0, 'Avstånd från husfasad får inte vara negativt'),
    }),
  });
};

export const emissionSourcesSchema = (stationClassification) => {
  return Yup.object().shape({
    emissionSources: Yup.object().shape({
      emissionSource: Yup.string()
        .nullable()
        .min(0, 'Huvudsaklig utsläppskälla måste anges')
        .required('Huvudsaklig utsläppskälla måste anges'),
      distanceStreet:
        stationClassification === SELECTEDTYPES.TRAFFIC &&
        Yup.number()
          .nullable()
          .typeError('Avstånd till väg måste vara ett nummer')
          .required('Avstånd till väg måste anges')
          .min(0, 'Avstånd till väg får inte vara negativt'),
      industrialEmissions:
        stationClassification === SELECTEDTYPES.INDUSTRI &&
        Yup.number()
          .nullable()
          .typeError('Avstånd till industrikällor måste vara ett nummer')
          .required('Avstånd till industrikällor måste anges'),
      distanceIndustry:
        stationClassification === SELECTEDTYPES.INDUSTRI &&
        Yup.number()
          .nullable()
          .typeError('Avstånd till industrikällor måste vara ett nummer')
          .required('Avstånd till industrikällor måste anges')
          .min(0, 'Avstånd till industrikällor får inte vara negativt'),
    }),
  });
};
